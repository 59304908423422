/**
 * Check the value is scientific notation or not
 * @param {string} value raw value
 * @returns {boolean}
 */
export const isScientificNotation = (value) => {
  // Regular expression to match scientific notation
  const scientificNotationRegex = /^[+-]?(\d+(\.\d*)?|\.\d+)([eE][+-]?\d+)$/;
  return scientificNotationRegex.test(value);
};

/**
 * Get the length of a float number precision length
 * @param {number | string} target Target value
 */
export const getPrecisionLength = (target) => {
  if (target === null || target === undefined) return 2;
  const strTarget = target.toString().trim();
  if (!strTarget.length) return 2;
  const numList = strTarget.split('.');
  return numList.length < 2 ? 2 : Math.max(numList[1].length, 2);
};

/**
 * Check the value is valid or not
 * @description eg: null, undefined, NaN, '', "" will be invalid
 * @param {string|number} value The value to be checked
 * @returns {boolean}
 */
export const isNill = (value) => {
  return (
    value === null ||
    value === undefined ||
    value === 'null' ||
    value === 'undefined' ||
    value === '' ||
    value === '""' ||
    value === "''" ||
    value === '“”' ||
    value === '‘’' ||
    value === 'NaN'
    // Number.isNaN(value)
  );
};

/**
 * Check the value is a Date style or not
 * @param {string} value The value to be checked
 * @returns {{isUsDate: boolean, isCnDate: boolean, isISODate: boolean, isTimestamp: boolean}}
 */
export const isDateStyle = (value) => {
  //  ISO 时间格式： 2021-03-29T15:59:17.000Z, 2021/03/29T15:59:17.000Z
  const regISO = [
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.*$/, // yyyy-mm-ddThh:mm:ss
    /^\d{4}\/\d{2}\/\d{2}T\d{2}:\d{2}:\d{2}.*$/, // yyyy/mm/ddThh:mm:ss
  ];

  // timestamp 时间格式(milliseconds, seconds)： 1617023957000 1617023957
  const regTimestamp = [
    /^\d+$/, // random lenth
    /^\d{13}$/, // milliseconds
    /^\d{10}$/, // seconds
    /^\d{13}\.\d{3}$/, // milliseconds with 3 decimal places
    /^\d{10}\.\d{3}$/, // seconds with 3 decimal places
  ];

  const regListUS = [
    /^\d{2}\/\d{2}\/\d{4}$/, // mm/dd/yyyy
    /^\d{2}\/\d{2}$/, // mm/dd
  ];
  const regListCN = [
    /^\d{4}\/\d{2}\/\d{2}$/, // yyyy/mm/dd
    /^\d{4}\/\d{2}$/, // yyyy/mm
  ];

  let res = null;
  if (regISO.some((reg) => reg.test(value))) {
    res = 'iso';
  } else if (regListUS.some((reg) => reg.test(value))) {
    res = 'us';
  } else if (regListCN.some((reg) => reg.test(value))) {
    res = 'cn';
  } else if (regTimestamp.some((reg) => reg.test(value))) {
    res = 'timestamp';
  }
  return {
    isUsDate: res === 'us',
    isCnDate: res === 'cn',
    isISODate: res === 'iso',
    isTimestamp: res === 'timestamp',
  };
};

/**
 * convert excel date to js date: 44941 => 2023-01-11
 * @param {number} serial
 * @returns timestamp
 */
export function convertExcelDateToJSDate(serial) {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  return utc_value * 1000;
}

/**
 * Check the value is a standard currency or not
 * @description eg: 1,234.56, -1234.56, $1,234, -$1,234.56
 * @param {string | number} value target value
 * @returns {boolean}
 */
export const isCurrencyStyle = (value) => {
  const regList = [
    /^-?\$?\d{1,3}(,\d{3})*(\.\d+)?$/, // -$1,234.56, $1,234, 1,234.56
  ];
  return regList.some((reg) => reg.test(value.toString()));
};

/**
 * Check the value is a percentage style or not
 * @description eg: 1.23%, -1.23%, 1.23, 1.230000000000045%
 * @param {string|number} value The value to be checked
 * @returns {boolean}
 */
export const isPercentageStyle = (value) => {
  const regList = [
    /^-?\d+(\.\d+)?%?$/, // 1.23%, -1.23, 1.230000000000045%
  ];
  return regList.some((reg) => reg.test(value.toString()));
};

/**
 * Check the time is in the range or not
 * @param {number} date The timestamp of date
 */
export const isValideDate = (date) => {
  // set the time range from 1980-01-01 to 2200-01-01
  const timeRange = [315532800000, 7258118400000];
  return +date >= timeRange[0] && +date <= timeRange[1];
};

/**
 * Check the file is excel or not
 * @param {string} path file path
 * @returns {boolean}
 */
export const isExcel = (path) => {
  return path.endsWith('.xlsx') || path.endsWith('.xls');
};

/**
 * parse the data to the string format used in the url
 * @description eg: {a: 1, b: 2} => a=1&b=2
 * @param {object} data The data to be parsed
 */
export const serializeData = (data) => {
  return Object.keys(data)
    .filter((key) => !isNill(data[key]))
    .map((key) => {
      if (Array.isArray(data[key])) {
        return data[key].map((item) => `${key}=${item}`).join('&');
      } else {
        return `${key}=${data[key]}`;
      }
    })
    .join('&');
};
