import { RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { clone } from 'lodash-es';

import MultiSelect from '@/components/molecules/MultiSelect';

const transformers = [
  { id: 'toLowerCase', label: 'Convert to lower case' },
  { id: 'toUpperCase', label: 'Convert to upper case' },
  { id: 'removeDashes', label: 'Remove dashes' },
  { id: 'removeSpaces', label: 'Remove spaces' },
  { id: 'removeCommas', label: 'Remove commas' },
  { id: 'removePeriods', label: 'Remove periods' },
  { id: 'removeApostrophes', label: 'Remove apostrophes' },
  { id: 'normalizeSpaces', label: 'Normalize spaces' },
  { id: 'normalizeCompany', label: 'Normalize company names' },
  { id: 'normalizeSymbols', label: 'Normalize symbols (&)' },
  { id: 'trimZeros', label: 'Trim zeros (leading and trailing)' },
  { id: 'removeLetters', label: 'Remove letters (6+ numbers required)' },
  { id: 'removedashnn', label: 'Remove "-##" and "_##"' },
  { id: 'removespacenn', label: 'Remove " ##"' },
  { id: 'removeFromEnd', label: 'Remove last n characters (n=1 atm)' },
];

const additionalSettings = [
  { id: 'ignoreEmpty', label: 'Ignore empty values' },
  {
    id: 'startsWithMatch',
    label: 'Consider startsWith a match',
    tip: 'If the longer string starts with the shorter string, consider this a match (requires exact match)',
  },
  {
    id: 'simiarlityOnShorterLength',
    label: 'Perform similarity on shorter length',
    tip: 'Perform similarty scoring on the length of the shorter string (truncates longer string to same length as shorter string for comparison)',
  },
];

const emptyRow = { field: '', transformers: [], params: '' };

const SimilarityConfig = ({
  fieldId,
  data: rowData,
  setter,
  reportFields,
  statementFields,
}) => {
  const data =
    typeof rowData[fieldId] === 'string'
      ? [clone(emptyRow)]
      : (rowData[fieldId] ?? [clone(emptyRow)]);

  return (
    <Box key={fieldId}>
      {data?.map((datum, i) => (
        <Box key={i} sx={{ mb: 1, display: 'flex' }}>
          <FormControl sx={{ mr: 2 }}>
            <InputLabel id={datum.id}>Report field</InputLabel>
            <Select
              label="Report field"
              labelId={datum.id}
              value={datum.reportField}
              onChange={(e) => {
                const newdatums = [...data];
                newdatums[i].reportField = e.target.value;
                setter({ ...rowData, [fieldId]: newdatums });
              }}
              sx={{ minWidth: 180 }}
            >
              {reportFields?.map((field) => (
                <MenuItem key={field.id} value={field.id}>
                  {field.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex' }}>
            <MultiSelect
              label="Transformers"
              values={transformers}
              formatter={(transformer) => transformer.label}
              valuer={(transformer) => transformer.id}
              filteredValues={data[i].reportTransformers}
              setFilteredValues={(newValues) => {
                const newdatums = [...data];
                newdatums[i].reportTransformers =
                  typeof newValues === 'string'
                    ? newValues.split(',')
                    : newValues;
                setter({ ...rowData, [fieldId]: newdatums });
              }}
              sx={{ width: 120, mr: 2 }}
            />
          </Box>
          {/* <TextField
            label="Params"
            value={datum.params}
            onChange={(e) => {
              const newdatums = [...data];
              newdatums[i].params = e.target.value;
              setter({ ...rowData, [fieldId]: newdatums });
            }}
            
            sx={{ mr: 2, minWidth: 180 }}
          /> */}
          <FormControl sx={{ mr: 2 }}>
            <InputLabel id={datum.id}>Statement field</InputLabel>
            <Select
              label="Statement field"
              labelId={datum.id}
              value={datum.statementField}
              onChange={(e) => {
                const newdatums = [...data];
                newdatums[i].statementField = e.target.value;
                setter({ ...rowData, [fieldId]: newdatums });
              }}
              sx={{ minWidth: 180 }}
            >
              {statementFields?.map((field) => (
                <MenuItem key={field.id} value={field.id}>
                  {field.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex' }}>
            <MultiSelect
              label="Transformers"
              values={transformers}
              formatter={(transformer) => transformer.label}
              valuer={(transformer) => transformer.id}
              filteredValues={data[i].statementTransformers}
              setFilteredValues={(newValues) => {
                const newdatums = [...data];
                newdatums[i].statementTransformers =
                  typeof newValues === 'string'
                    ? newValues.split(',')
                    : newValues;
                setter({ ...rowData, [fieldId]: newdatums });
              }}
              sx={{ width: 120, mr: 2 }}
            />
          </Box>
          {/* <TextField
            label="Params"
            value={datum.params}
            onChange={(e) => {
              const newdatums = [...data];
              newdatums[i].params = e.target.value;
              setter({ ...rowData, [fieldId]: newdatums });
            }}
            sx={{ mr: 2, minWidth: 180 }}
          /> */}
          <Box sx={{ display: 'flex' }}>
            <MultiSelect
              label="Settings"
              values={additionalSettings}
              formatter={(transformer) => transformer.label}
              valuer={(transformer) => transformer.id}
              filteredValues={data[i].additionalSettings}
              setFilteredValues={(newValues) => {
                const newdatums = [...data];
                newdatums[i].additionalSettings =
                  typeof newValues === 'string'
                    ? newValues.split(',')
                    : newValues;
                setter({ ...rowData, [fieldId]: newdatums });
              }}
              noall
              sx={{ width: 120, mr: 2 }}
            />
          </Box>
          <TextField
            label="Threshold"
            value={datum.threshold}
            onChange={(e) => {
              const newdatums = [...data];
              newdatums[i].threshold = e.target.value;
              setter({ ...rowData, [fieldId]: newdatums });
            }}
            sx={{ mr: 2, maxWidth: 82 }}
          />
          <IconButton
            onClick={() => {
              const newdatums = [...data];
              newdatums.splice(i, 1);
              setter({ ...rowData, [fieldId]: newdatums });
            }}
          >
            <RemoveCircleOutline />
          </IconButton>
        </Box>
      ))}
      <Button
        onClick={() =>
          setter({ ...rowData, [fieldId]: [...data, clone(emptyRow)] })
        }
      >
        Add
      </Button>
    </Box>
  );
};
export default SimilarityConfig;
