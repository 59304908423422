import { Box, Chip, Skeleton, Typography } from '@mui/material';

import AgentCommissionSplitConfig from '@/components/PolicyDataView/AgentCommissionSplitConfig';
import states from '@/data/states.json';
import Formatter from '@/services/Formatter';
import UILabels from '@/services/UILabels';
import { FieldTypes, Roles } from '@/types';
import DataTransformation from '@/services/DataTransformation';
const Normalizer = DataTransformation;

class Reports {
  #mode = 'default';

  #strings = new UILabels(this.#mode);

  outstandingFieldsInMobileView = [];

  label = 'Transactions';

  table = 'report_data';

  options = {};

  constructor(mode, options) {
    this.options = options;
    this.#mode = mode;
    this.#strings = new UILabels(mode);
    this.deDupeFields = options?.deDupeFields ?? this.deDupeFields;
    this.label = this.#strings.getLabel('transactions', 'title');
    this.labelSimple = this.#strings.getLabel('transactions', 'titleSimple');
    this.filters = {
      agent_name: {
        label: 'Agent name',
      },
      contacts: {
        label: 'Agents',
      },
      document_id: {
        label: 'Document',
      },
      policy_status: {
        label: 'Status',
      },
      product_name: {
        label: 'Product name',
      },
      product_type: {
        label: 'Product type',
      },
      writing_carrier_name: {
        label: 'Master company',
      },
      account_type: {
        label: 'Account type',
      },
    };
    this.fields = {
      agent_name: {
        label: this.#strings.getLabel('transactions', 'agent'),
        options: [
          'assigned agent',
          'agent',
          'agt',
          'writing agent',
          'writing agt',
          'agent name',
          'agt name',
          'assigned_agent',
          'producer name',
          'assigned producer',
        ],
        enabled: true,
        global: true,
      },
      customer_name: {
        label: 'Customer name',
        options: [
          'customer name',
          'customer',
          'insuree',
          'client',
          'client name',
          'insured name',
          'policy holder',
          'policy holder name',
          'subscriber',
          'name of insured',
          'customername',
          'account name',
        ],
        required: false,
        reconciler: true,
        enabled: true,
      },
      dba: {
        label: 'DBA',
        options: ['dba', 'aka'],
        required: false,
        reconciler: true,
        enabled: true,
      },
      // customerFirstName: {
      //   label: 'Insured first name',
      //   options: ['customer first name', 'first name'],
      //   required: 'name',
      //   enabled: true,
      // },
      // customerLastName: {
      //   label: 'Insured last name',
      //   options: ['customer last name', 'last name'],
      //   required: 'name',
      //   enabled: true,
      // },
      policy_id: {
        label: this.#strings.getLabel('transactions', 'salesId'),
        options: [
          'policy id',
          'policy no',
          'policy number',
          'policy #',
          'policy_number',
          'policyid',
        ],
        formatter: (s) =>
          Formatter.policyNumber(s?.toString() ?? '', {
            account_id: this.options?.account_id,
          }),
        normalizer: (s) => s?.toString() ?? '',
        enabled: true,
        reconciler: true,
        copyable: true,
      },
      internal_id: {
        label: 'Internal Id',
        options: ['internal id', 'internalid'],
        enabled: true,
      },
      effective_date: {
        // date
        label: this.#strings.getLabel('transactions', 'startDate'),
        options: [
          'policy effective date',
          'effective date',
          'effective',
          'eff date',
          'in force date',
          'effective_date',
          'effective_date2',
          'effectivedate',
        ],
        enabled: true,
        formatter: Normalizer.formatDate,
        normalizer: Normalizer.normalizeDate,
        required: false,
        type: 'date',
      },
      signed_date: {
        label: 'Signed date',
        type: 'date',
        enabled: true,
        formatter: Normalizer.formatDate,
        normalizer: Normalizer.normalizeDate,
        required: false,
      },
      policy_date: {
        label: 'Policy date',
        type: 'date',
        enabled: true,
        formatter: Normalizer.formatDate,
        normalizer: Normalizer.normalizeDate,
        required: false,
      },
      writing_carrier_name: {
        label: this.#strings.getLabel('transactions', 'payingEntity'),
        options: [
          'mga/broker',
          'mga',
          'broker',
          'carrier name',
          'carrier',
          'carriername',
        ],
        enabled: true,
        required: false,
        reconciler: true,
        global: true,
        type: 'dynamic-select',
        table: 'companies',
        field: 'company_name',
        formatter: (val, collectionVals = []) => {
          if (
            val &&
            Array.isArray(collectionVals) &&
            collectionVals.length > 0
          ) {
            const datum = collectionVals?.filter(
              (company) => company.company_name === val
            )?.[0];
            return datum ? (
              <Chip
                key={val}
                label={`${datum.company_name}`}
                clickable
                component="a"
                href={`/companies?q=${val}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            ) : (
              `${val} (not found in Fintary)`
            );
          }
          return val;
        },
        optionFormatter: (option) => option.company_name,
        optionValuer: (option) => option.company_name,
      },
      premium_amount: {
        label: this.#strings.getLabel('transactions', 'annualizedRevenue'),
        description:
          'Annualized premium amount as recorded in production reports',
        options: [
          'policy premium',
          'premium amount',
          'premium amt',
          'premium paid',
          'premium',
          'premium - annualized',
          'annualized_premium',
          'annualized_premium2',
          'premiumamount',
        ],
        required: false,
        enabled: true,
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
        type: 'currency',
      },
      product_type: {
        label: 'Product type',
        options: [
          'product type',
          'product line',
          'line_of_business',
          'producttype',
        ],
        enabled: true,
      },
      product_sub_type: {
        label: 'Product sub type',
        options: [],
        enabled: true,
      },
      product_name: {
        label: 'Product name',
        options: ['product name', 'product'],
        reconciler: true,
        enabled: this.#mode === 'insurance',
        type: 'dynamic-select',
        table: 'companies/products',
        queryParamName: 'company_name',
        queryParamValue: 'writing_carrier_name',
        field: 'product_name',
        formatter: (val, collectionVals = []) => {
          if (
            val &&
            Array.isArray(collectionVals) &&
            collectionVals.length > 0
          ) {
            const datum = collectionVals?.filter(
              (company) => company.product_name === val
            )?.[0];
            return datum ? (
              <Chip
                key={val}
                label={`${datum.product_name}`}
                clickable
                component="a"
                href={`/companies/products?q=${val}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            ) : (
              `${val} (not found in Fintary)`
            );
          }
          return val;
        },
        optionFormatter: (option) => option.product_name,
        optionValuer: (option) =>
          option.product_name ? option.product_name : '',
      },
      product_option_name: {
        label: 'Product option name',
        options: ['option', 'product option'],
        reconciler: true,
        enabled: this.#mode === 'insurance',
      },
      cancellation_date: {
        label: 'Cancellation date',
        enabled: true,
        formatter: Normalizer.formatDate,
        normalizer: Normalizer.normalizeDate,
        type: 'date',
      },
      reinstatement_date: {
        label: 'Reinstatement date',
        enabled: true,
        formatter: Normalizer.formatDate,
        normalizer: Normalizer.normalizeDate,
        type: 'date',
      },
      transaction_type: {
        // new, renewal, cancellation, rewrite, fees
        label: 'Transaction type',
        options: [
          'policy business type',
          'transaction type',
          'commission type',
          'policy type',
          'transactiontype',
        ],
        required: false,
        enabled: this.#mode === 'insurance',
      },
      commissions_expected: {
        label: 'Commissions due',
        description: 'Commission amount due as recorded in production reports',
        options: [
          'agency commission total',
          'commissions expected',
          'commission expected',
          'commissionsexpected',
        ],
        required: false,
        enabled: this.#mode === 'insurance',
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
        type: 'currency',
      },
      policy_status: {
        label: 'Status',
        description: 'Policy status',
        options: ['status', 'active', 'policystatus'],
        required: false,
        enabled: true,
      },
      account_type: {
        label: 'Account type',
        required: false,
        enabled: true,
        options: ['account type', 'accounttype'],
      },
      geo_state: {
        label: 'State',
        type: 'select',
        options: states,
        enabled: true,
      },
      split_percentage: {
        label: 'Split percentage',
        enabled: true,
        options: ['split percentage'],
        formatter: Normalizer.formatPercentage,
        normalizer: Normalizer.normalizePercentage,
        type: FieldTypes.PERCENTAGE,
      },
      group_name: {
        label: 'Group Name',
        options: ['group name'],
        required: false,
        enabled: true,
      },
      payment_mode: {
        label: 'Payment Mode',
        options: ['payment mode'],
        required: false,
        enabled: true,
      },

      policy_term_months: {
        label: 'Policy term (months)',
        required: false,
        enabled: true,
        normalizer: Normalizer.normalizeInt,
        options: ['policy term', 'policyterm'],
        type: 'integer',
      },
      commissionable_premium_amount: {
        label: 'Target premium',
        options: ['target premium', 'commissionable premium'],
        enabled: true,
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
        type: 'currency',
      },
      notes: {
        label: 'Notes',
        options: ['notes', 'note', 'remark', 'notation', 'memo'],
        required: false,
        enabled: true,
      },
      group_id: {
        label: 'Group Id',
        options: [
          'group id',
          'group number',
          'group no',
          'grp #',
          'grp number',
          'groupid',
        ],
        enabled: this.#mode === 'insurance',
        required: false,
      },
      contacts: {
        label: 'Agents',
        required: false,
        enabled: true,
        type: 'dynamic-select',
        multiple: true,
        table: 'contacts',
        formatter: (val, collectionVals = []) => {
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const datum = collectionVals?.find((datum) => datum.str_id === val);
            return datum ? (
              <Chip
                key={val}
                label={Formatter.contact(datum, {
                  account_id: this.options.account_id,
                })}
                clickable
                component="a"
                href={`/agents/list?id=${val}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            ) : (
              `${val} (not found in Fintary)`
            );
          }
          return val; // not formatting when collectionVals is not available
        },
        optionFormatter: (option) =>
          Formatter.contact(option, { account_id: this.options.account_id }),
        optionValuer: (option) => option.str_id,
        // preSetter: (row) => {
        //   row.contacts_split = Object.fromEntries(
        //     Object.entries(row.contacts_split ?? {}).filter(([k, v]) =>
        //       (row.contacts ?? []).includes(k)
        //     )
        //   );
        //   return row;
        // },
      },
      contacts_split: {
        label: 'Agent policy split',
        enabled: true,
        type: 'custom',
        table: 'contacts',
        tableFormatter: (val, row, dynamicSelects) =>
          val && (
            <Box>
              {Object.keys(val).length > 0 && dynamicSelects ? (
                <Box>
                  {Object.entries(val ?? {}).map(([k, v]) => (
                    <Box key={k}>
                      {Formatter.contact(
                        dynamicSelects?.find((e) => e.str_id === k) ?? {},
                        { account_id: this.options.account_id }
                      )}
                      : {v}%
                    </Box>
                  ))}
                  {Object.values(val ?? {}).reduce((a, b) => +a + +b, 0) !==
                    100 && (
                    <Typography variant="caption" sx={{ color: 'red' }}>
                      Total should be 100
                    </Typography>
                  )}
                </Box>
              ) : Object.keys(val).length > 0 && !dynamicSelects ? (
                <Skeleton variant="rectangular" />
              ) : null}
            </Box>
          ),
        render: (field, row, setter, dynamicSelects) => (
          <AgentCommissionSplitConfig
            title="Agent policy split"
            key={'agent-policy-split-config'}
            data={row}
            contacts={row.contacts}
            split={row.contacts_split}
            setter={setter}
            setterKey={'contacts_split'}
            dynamicSelects={dynamicSelects}
          />
        ),
        access: [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST],
      },
      contacts_commission_split: {
        label: 'Agent commission split',
        enabled: true,
        type: 'custom',
        table: 'contacts',
        tableFormatter: (val, row, dynamicSelects) =>
          val && (
            <Box>
              {Object.keys(val).length > 0 && dynamicSelects ? (
                <Box>
                  {Object.entries(val ?? {}).map(([k, v]) => (
                    <Box key={k}>
                      {Formatter.contact(
                        dynamicSelects?.find((e) => e.str_id === k) ?? {},
                        { account_id: this.options.account_id }
                      )}
                      : {v}%
                    </Box>
                  ))}
                  {Object.values(val ?? {}).reduce((a, b) => +a + +b, 0) !==
                    100 && (
                    <Typography variant="caption" sx={{ color: 'red' }}>
                      Total should be 100
                    </Typography>
                  )}
                </Box>
              ) : Object.keys(val).length > 0 && !dynamicSelects ? (
                <Skeleton variant="rectangular" />
              ) : null}
            </Box>
          ),
        render: (field, row, setter, dynamicSelects) => (
          <AgentCommissionSplitConfig
            title="Agent commission split"
            key={'agent-commission-split-config'}
            data={row}
            contacts={row.contacts}
            split={row.contacts_commission_split}
            setter={setter}
            setterKey={'contacts_commission_split'}
            dynamicSelects={dynamicSelects}
          />
        ),
        access: [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST],
      },
      issue_age: {
        label: 'Issue age',
        required: false,
        enabled: true,
        normalizer: Normalizer.normalizeInt,
        options: ['issue age'],
        type: 'integer',
      },
      customer_paid_premium_amount: {
        label: 'Customer Paid Premium Amount',
        required: false,
        enabled: true,
        options: ['Basis', 'customer paid premium amount'],
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
      },
      aggregation_id: {
        label: 'Aggregation Id',
        enabled: true,
      },
      aggregation_primary: {
        label: 'Head of household',
        type: 'boolean',
        enabled: true,
        normalizer: Normalizer.normalizeBoolean,
        formatter: Formatter.boolean,
      },
      statement_data: {
        label: 'Commission statements',
        required: false,
        enabled: true,
        type: 'custom',
        tableFormatter: (vals) => (
          <Box key="statement_data">
            {vals.map((val) => (
              <Chip
                key={val.str_id}
                label={val.str_id}
                clickable
                component="a"
                href={`/commissions?id=${val.str_id}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            ))}
          </Box>
        ),
        render: (field, row) => {
          return row && Array.isArray(row.statement_data) ? (
            <Box key="statement_data">
              <Typography>Commission statements</Typography>
              {row.statement_data.map((val) => (
                <Chip
                  key={val.str_id}
                  label={val.str_id}
                  clickable
                  component="a"
                  href={`/commissions?id=${val.str_id}`}
                  target="_blank"
                  sx={{ m: 0.1 }}
                />
              ))}
            </Box>
          ) : null;
        },
        readOnly: true,
      },
      children_report_data: {
        label: 'Linked policies',
        required: false,
        enabled: true,
        type: 'custom',
        tableFormatter: (vals) => (
          <Box key="children_report_data">
            {vals.map((val) => (
              <Chip
                key={val.str_id}
                label={val.str_id}
                clickable
                component="a"
                href={`/policies?id=${val.str_id}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            ))}
          </Box>
        ),
        render: (field, row) => {
          return row && Array.isArray(row.children_report_data) ? (
            <Box key="children_report_data">
              <Typography>Linked policies</Typography>
              {row.children_report_data.map((val) => (
                <Chip
                  key={val.str_id}
                  label={val.str_id}
                  clickable
                  component="a"
                  href={`/policies?id=${val.str_id}`}
                  target="_blank"
                  sx={{ m: 0.1 }}
                />
              ))}
            </Box>
          ) : null;
        },
        readOnly: true,
      },
      type: {
        id: 'type',
        label: 'Type',
        readOnly: true,
        hidden: true,
      },
      document_id: {
        label: 'Document',
        options: ['document id', 'document'],
        enabled: true,
        required: false,
        type: 'dynamic-select',
        table: 'documents',
        queryParamValue: 'report',
        queryParamName: 'type',
        formatter: (val, collectionVals = []) => {
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const datum = collectionVals?.find((datum) => datum.str_id === val);
            return datum ? (
              <Chip
                key={val}
                label={`${datum.filename}`}
                clickable
                component="a"
                href={`/documents?id=${datum?.str_id}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            ) : (
              `${val} (not found in Fintary)`
            );
          }
          return val;
        },
        optionFormatter: (option) => `${option.filename}`,
        optionValuer: (option) => option.str_id,
      },
      processing_status: {
        label: 'Processing status',
        options: ['new', 'processed', 'frozen'],
        type: 'select',
        enabled: true,
      },
      first_payment_date: {
        label: 'First payment date',
        enabled: true,
        formatter: Normalizer.formatDate,
        normalizer: Normalizer.normalizeDate,
        type: 'date',
      },
      first_processed_date: {
        label: 'First processed date',
        enabled: true,
        formatter: Normalizer.formatDate,
        normalizer: Normalizer.normalizeDate,
        type: 'date',
      },
    };

    // Add field key as field property
    // Delete fields that are not enabled
    Object.entries(this.fields).forEach(([k, v]) => {
      this.fields[k].id = k;
      if (!v.enabled) {
        delete this.fields[k];
      }
    });

    this.fieldsCollapsed = Object.values(this.fields)
      .map((field) => field.options)
      .flat();

    this.outstandingFieldsInMobileView = [
      'policy_id',
      'customer_name',
      'effective_date',
      'carrier_name',
      'premium_amount',
    ];
  }

  getDataInfo = (data, topN = 5) => {
    const rowData = [];
    const ranges = [];
    let curRange = { start: 0 };
    data.forEach((row, i) => {
      let matches = 0;
      let filled = 0;
      row.forEach((cell) => {
        if (cell || cell === 0) {
          filled += 1;
        }
        if (this.fieldsCollapsed.includes(cell.toString().toLowerCase())) {
          matches += 1;
        }
      });
      if (filled === 0 && curRange !== null) {
        curRange.end = i - 1;
        ranges.push(curRange);
        curRange = null;
      } else if (filled > 0 && curRange === null) {
        curRange = { start: i };
      }
      rowData.push({ index: i, matches, filled, length: row.length });
      // allMatches[matches] = [...(allMatches?.[matches] || []), i];
    });
    if (curRange !== null) {
      ranges.push({ ...curRange, end: data.length - 1 });
    }

    const rangeData = rowData
      .sort((a, b) => b.matches - a.matches)
      .filter((row) => row.matches > 0)
      .splice(0, topN)
      .map((row) => ({
        index: row.index,
        count: row.matches,
        fields: data[row.index],
        data: data.filter(
          (dataRow, i) =>
            i > ranges.filter((a) => a.start === row.index)[0].start &&
            i <= ranges.filter((a) => a.start === row.index)[0].end
        ),
      }));
    return { rowData, ranges, rangeData };
  };
}

export default Reports;
