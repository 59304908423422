import { DeleteOutline } from '@mui/icons-material';
import {
  Alert,
  Chip,
  IconButton,
  Tooltip,
  styled,
  tooltipClasses,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { BasicDialog } from '@/common';
import DataView from '@/components/DataView';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 900,
  },
});

const ExtractionsView = () => {
  const [refresh, setRefresh] = useState(0);
  const [showDelConfirm, setShowDelConfirm] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const { setLoadingConfig } = useContext(LoadingContext);
  /**
   * @type Object
   */
  const { openSnackbar } = useOutletContext();
  const extractionsDelter = API.getMutation('extractions', 'DELETE');

  const dataDesc = {
    label: 'Extractions',
    table: 'extractions',
    fields: [
      { id: 'str_id', label: 'Id', copyable: true },
      {
        id: 'created_at',
        numeric: false,
        label: 'Created at',
        formatter: (s) => new Date(s).toLocaleString(),
      },
      {
        id: 'method',
        numeric: false,
        label: 'Method',
      },
      {
        id: 'documents',
        numeric: false,
        label: 'Document name',
        tableFormatter: (s, row) => {
          return (
            <Chip
              label={row.documents?.filename}
              clickable
              component="a"
              href={`/documents?id=${row.documents?.str_id}`}
              target="_blank"
            />
          );
        },
        formatter: (s, row) => {
          return row.documents?.filename;
        },
      },
      {
        id: 'result',
        label: 'Result',
        formatter: (s, row) => {
          return `${s} (${row?.status})`;
        },
      },
      // {
      //   id: 'output',
      //   numeric: false,
      //   label: 'Extracted Data Key',
      //   formatter: (s) => {
      //     // show max 600 chars
      //     return (
      //       <CustomWidthTooltip
      //         title={JSON.stringify(
      //           JSON.parse(s)?.Tables || JSON.parse(s)?.table || ''
      //         )}
      //         arrow
      //       >
      //         <span>{Object.keys(JSON.parse(s)).toString()}</span>
      //       </CustomWidthTooltip>
      //     );
      //   },
      // },
      {
        id: 'id',
        numeric: false,
        label: 'Actions',
        formatter: (id, row) => {
          return (
            <IconButton
              className="ml-1"
              onClick={async () => {
                setCurrentRow(row);
                setShowDelConfirm(true);
              }}
            >
              <DeleteOutline />
            </IconButton>
          );
        },
      },
    ],
  };

  const DelConfirmComp = ({ row }) => {
    return (
      <BasicDialog
        title="Extraction Data Delete"
        open={showDelConfirm}
        onClose={(val) => {
          if (val) {
            setLoadingConfig({
              loading: true,
              message: 'Deleting...',
            });
            extractionsDelter
              .mutateAsync({ ids: [row.id] })
              .then(() => {
                openSnackbar(
                  <Alert severity="success">
                    Extraction data deleted successfully
                  </Alert>
                );
                setRefresh(refresh + 1);
                setLoadingConfig({
                  loading: false,
                  message: '',
                  delayToClose: 1000,
                });
                setCurrentRow(null);
              })
              .catch((err) => {
                openSnackbar(
                  <Alert severity="error">{err.message || err}</Alert>
                );
                setLoadingConfig({
                  loading: false,
                  message: '',
                });
              });
          } else {
            setShowDelConfirm(false);
            setCurrentRow(null);
          }
        }}
        bodyComponent={
          <Alert severity="warning">
            Are you sure you want to delete the extraction: {row.str_id}?
          </Alert>
        }
      />
    );
  };

  return (
    <>
      <DataView dataDesc={dataDesc} viewOnly refresh={refresh} />
      {showDelConfirm && currentRow && <DelConfirmComp row={currentRow} />}
    </>
  );
};

ExtractionsView.propTypes = {
  user: PropTypes.object,
  openSnackbar: PropTypes.func,
};

export default ExtractionsView;
