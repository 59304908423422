import { GpsFixedOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  TextField,
  useMediaQuery,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useMemo, useState } from 'react';

const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 4;

// TODO: Change file to .tsx and add prop types
const MultiSelect = ({
  label,
  values = [],
  filteredValues = [],
  setFilteredValues,
  formatter = (val, isOpen) => val,
  valuer = (val) => val,
  sx = {},
  noall = false,
  enableSearch = false,
  paginate = false,
  paginateStep = 100,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 18.5 + ITEM_PADDING_TOP,
        minWidth: 240,
        maxWidth: isMobile ? undefined : 640,
      },
    },
    MenuListProps: {
      style: {
        paddingBottom: 4,
      },
    },
    autoFocus: false,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [visibleCount, setVisibleCount] = useState(
    paginate ? paginateStep : 10000
  );

  const hasOnlyNameAndId = (value) => {
    return (
      ['name', 'id'].every((key) => Object.keys(value ?? {}).includes(key)) &&
      Object.keys(value).length === 2
    );
  };

  const valuesMap = useMemo(
    () =>
      typeof values[0] === 'object'
        ? values.reduce((acc, cur) => {
            acc.set(
              typeof valuer(cur) === 'object' ? cur.id : valuer(cur),
              cur
            );
            return acc;
          }, new Map())
        : null,
    [values]
  );

  // TODO: Update filteredValues to selectedValues
  // filteredValues/setFilteredValues are for external use (MultiSelect originally created for filters)
  // selectedValues/setSelectedValues would be a better name
  const valuesFiltered = useMemo(
    () =>
      values.filter((value) => {
        const formattedValue = hasOnlyNameAndId(value) ? value.name : value;
        if (query === '') return true;
        return formatter(formattedValue)
          ?.toLowerCase()
          ?.includes(query.toLowerCase());
      }),
    [values, query]
  );

  const valuesFilteredVisible = valuesFiltered?.slice(0, visibleCount);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value[value.length - 1] === 'all') {
      setFilteredValues(
        filteredValues.filter((val) => val !== '').length === values.length
          ? ['']
          : valuesMap
            ? Array.from(valuesMap.keys())
            : values
      );
    } else {
      setFilteredValues(typeof value === 'string' ? value.split(',') : value);
    }
  };

  const pickMePickMe = (e, value) => {
    e.stopPropagation();
    setFilteredValues([value]);
  };

  return (
    <div>
      <FormControl sx={sx}>
        <InputLabel
          id="multiple-checkbox-label"
          sx={{
            lineHeight: 1.1,
            ...(filteredValues.length === values.length
              ? {}
              : { color: '#2196f3' }),
          }}
        >
          {label}
        </InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={
            Array.isArray(filteredValues)
              ? filteredValues.filter((val) => val !== '')
              : [filteredValues]
          }
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              const selectedWithoutEmpty = selected.filter((val) => val !== '');
              return selectedWithoutEmpty.length === values.length
                ? `All (${selectedWithoutEmpty.length})`
                : selectedWithoutEmpty.length === 0
                  ? 'None'
                  : `(${selectedWithoutEmpty.length}) ${
                      valuesMap
                        ? selectedWithoutEmpty
                            .map(
                              (item) => formatter(valuesMap.get(item), isOpen),
                              isOpen
                            )
                            .join(', ')
                        : selectedWithoutEmpty.join(', ')
                    }`;
            }
            return selected;
          }}
          MenuProps={MenuProps}
          sx={{
            '.MuiSelect-select': {
              py: 0.75,
              px: 1.5,
            },
            ...(filteredValues.length === values.length
              ? {}
              : {
                  color: '#2196f3',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#2196f3',
                  },
                  '.MuiSvgIcon-root ': { fill: '#2196f3' },
                }),
          }}
        >
          {enableSearch && (
            <Box
              sx={{
                mb: 0.5,
                mx: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            >
              <TextField
                sx={{ flex: 1 }}
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                onClickCapture={(e) => {
                  console.log(e);
                  e.stopPropagation();
                }}
                placeholder="Search"
                // TODO: Fix issues with focus and propagation with the following
                // InputProps={{
                //   startAdornment: <Search sx={{ opacity: 0.5 }} />,
                //   endAdornment: (
                //     <IconButton
                //       onClick={() => {
                //         console.log('clickity');
                //         setQuery('');
                //       }}
                //       sx={{ pr: 0 }}
                //     >
                //       <Clear sx={{ opacity: 0.5 }} />
                //     </IconButton>
                //   ),
                //   style: { height: '34px' },
                // }}
              />
            </Box>
          )}
          {!noall && (
            <MenuItem value="all" dense>
              <Checkbox
                checked={filteredValues.length === values.length}
                sx={{ height: 24 }}
              />
              <ListItemText primary="All" />
            </MenuItem>
          )}
          {valuesFilteredVisible.map((value) => {
            const key = hasOnlyNameAndId(value) ? value.id : value;
            const displayValue = hasOnlyNameAndId(value) ? value.name : value;
            return (
              <MenuItem key={key} value={valuer(key)} dense>
                <Checkbox
                  checked={filteredValues.indexOf(valuer(key)) > -1}
                  sx={{ height: 24 }}
                />
                <ListItemText primary={formatter(displayValue, isOpen)} />
                <IconButton
                  sx={{
                    ml: 1,
                    opacity: 0,
                    '&:hover': { opacity: 1, color: '#2196f3' },
                  }}
                  onClick={(e) => pickMePickMe(e, valuer(key))}
                >
                  <GpsFixedOutlined sx={{ width: 16, height: 16 }} />
                </IconButton>
              </MenuItem>
            );
          })}
          {valuesFiltered?.length > visibleCount && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                mt: 0.5,
              }}
            >
              <Button
                onClick={() => {
                  setVisibleCount(visibleCount + paginateStep);
                }}
              >
                Show more ({valuesFiltered?.length - visibleCount})
              </Button>
            </Box>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultiSelect;
