import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { AccountIds } from 'common/constants';

import { useAccountStore, useRoleStore } from '@/store';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import Statements from '@/services/Statements';
import API from '@/services/API';
import { Roles } from '@/types';
import { UIStateContext } from '@/contexts/UIStateProvider';

const exportOptions = [
  {
    id: 'export',
    label: 'Export',
    options: {},
  },
  {
    id: 'export-producer-view',
    label: 'Export producer view',
    options: {
      producer_view: true,
      disabled: true,
      tooltip: 'Please select an Agent in the Agents filter to enable.',
    },
  },
];

const CommissionsDataView = ({ reportId = null }) => {
  const {
    role: [role],
  } = useContext(UIStateContext);
  const { selectedAccount } = useAccountStore();
  const { userRole } = useRoleStore();
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);
  const mode = selectedAccount?.accountMode;
  const statements = new Statements(mode, {
    account_id: selectedAccount?.accountId,
  });
  const isMobile = useMediaQuery('(max-width:600px)');

  const pageSettingFields = isMobile
    ? accountSettings?.pages_settings?.commissions?.outstandingMobileFields
    : accountSettings?.pages_settings?.commissions?.fields;
  const newFields = (pageSettingFields ?? []).reduce((acc, cur) => {
    acc[cur] = {
      ...statements.fields[cur],
    };
    if (
      role === 'admin' ||
      selectedAccount?.accountId === AccountIds.TRANSGLOBAL
    ) {
      // if (cur == 'premium_amount') {
      //   acc.expected_premium = statements.fields.expected_premium;
      // }
      // if (cur == 'commission_amount') {
      //   acc.expected_commission = statements.fields.expected_commission;
      // }
      // if (cur == 'commission_rate') {
      //   acc.expected_percentage = statements.fields.expected_percentage;
      // }
      if (cur == 'agent_commissions_log') {
        acc.expected_result = statements.fields.expected_result;
      }
    }
    return acc;
  }, {});

  if (Object.keys(newFields).length > 0) statements.fields = newFields;

  if (accountSettings?.pages_settings?.commissions?.page_label) {
    statements.label = accountSettings?.pages_settings?.commissions?.page_label;
  }

  if (
    isFetchedAccountSettings &&
    accountSettings?.pages_settings?.commissions?.show_page === false
  ) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/policies" />
      // <Box sx={{ textAlign: 'center', mt: 6, width: '100%' }}>
      //   <Typography variant="h5">No results</Typography>
      // </Box>
    );
  }
  return selectedAccount && isFetchedAccountSettings ? (
    <EnhancedDataView
      reportId={reportId}
      dataSpec={statements}
      exportOptions={exportOptions}
      outstandingMobileFields={
        accountSettings?.pages_settings?.commissions?.outstandingMobileFields
      }
      enableSaves
      showTotals
      // TODO (frank.santillan): Move to settings after we migrate reconciliation / commissions / policies to pages_settings.
      readOnly={userRole === Roles.PRODUCER}
    />
  ) : null;
};

export default CommissionsDataView;
