import { useState, useEffect, FC } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';

import BasicDatePicker from '@/components/molecules/BasicDatePicker';

export interface DateRangeValueProps {
  startDate: dayjs.Dayjs | string | null;
  startDateLabel?: string;
  endDate: dayjs.Dayjs | string | null;
  endDateLabel?: string;
}
export interface BasicDateRangePickerProps {
  range: DateRangeValueProps;
  onChange: (range: DateRangeValueProps) => void;
  key?: string;
  justify?: 'center' | 'left' | 'right';
}

const BasicDateRangePicker: FC<BasicDateRangePickerProps> = ({
  range,
  onChange,
  justify = 'center',
}) => {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  useEffect(() => {
    if (dateRange.startDate || dateRange.endDate) {
      onChange(dateRange);
    }
  }, [dateRange.startDate, dateRange.endDate]);

  const handleStartDateChange = (date) => {
    setDateRange({
      startDate: date,
      endDate: dateRange.endDate,
    });
    if (!dateRange.endDate) {
      setOpenEndDate(true);
    }
  };

  const handleEndDateChange = (date) => {
    setDateRange({
      startDate: dateRange.startDate,
      endDate: date,
    });
    if (!dateRange.startDate) {
      setOpenStartDate(true);
    }
    setOpenEndDate(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: { justify },
      }}
    >
      <BasicDatePicker
        label={range.startDateLabel || 'Start date'}
        value={range.startDate}
        setValue={handleStartDateChange}
        sx={{ width: 150 }}
        key="startDate"
        open={openStartDate}
        onOpen={() => setOpenStartDate(true)}
        onClose={() => setOpenStartDate(false)}
        InputProps={{ sx: { borderRadius: '32px 0 0 32px', pr: 1 } }}
      />
      <BasicDatePicker
        label={range.endDateLabel || 'End date'}
        value={range.endDate}
        key="endDate"
        setValue={handleEndDateChange}
        open={openEndDate}
        onOpen={() => setOpenEndDate(true)}
        onClose={() => setOpenEndDate(false)}
        sx={{ width: 150 }}
        InputProps={{ sx: { borderRadius: '0 32px 32px 0', pr: 1 } }}
      />
    </Box>
  );
};

export default BasicDateRangePicker;
