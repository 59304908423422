import { Alert, Box, FormControlLabel, Switch } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { ErrorMsg } from './process';

interface StickyTipsProps {
  tipsMap:
    | {
        [key: string]: string;
      }
    | ErrorMsg;
  rows: {
    [key: string]: string | number | boolean | null | string[];
  }[];
}

interface RowCheckType {
  key: string;
  val: string;
  index?: number;
  msg?: string;
}

const StickyTips = ({ rows, tipsMap }: StickyTipsProps, ref) => {
  const [ignoreErrors, setIgnoreErrors] = useState(false);
  const [rowTips, setRowTips] = useState<RowCheckType[]>([]);

  useEffect(() => {
    if (rows) {
      const result: RowCheckType[] = [];
      rows.map((row, index) => {
        const tips = Object.entries(row)
          .map(([key, val]) => {
            if (val && (val.toString() || '').includes('❌')) {
              const msg = key.includes('_date')
                ? ' should be a date (eg: 2024-01-01, 01/01/2024...)'
                : key.includes('_rate')
                  ? ' should be a rate (eg: 0.01, 1%, (1.00%)...)'
                  : key.includes('_amount')
                    ? ' should be a amount (eg: 100, $100, $100.00...)'
                    : '';
              return {
                key,
                val: val as string,
                index,
                msg,
              };
            }
          })
          .filter(Boolean);
        result.push(...(tips as RowCheckType[]));
      });
      setRowTips(result);
    }
  }, [rows]);

  useImperativeHandle(ref, () => ({
    ignoreErrors,
  }));

  return (
    <>
      {Object.entries(tipsMap).length && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
            backgroundColor: '#eee',
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            {Object.entries(tipsMap).map(([key, val]) => {
              return (key !== 'missingField' && val) ||
                (key === 'missingField' && !ignoreErrors && val) ? (
                <Alert severity="error" key={key}>
                  {val}
                </Alert>
              ) : null;
            })}
          </Box>
          <Box>
            {Object.values(tipsMap).filter((val) => val).length > 0 && (
              <FormControlLabel
                control={
                  <Switch
                    checked={ignoreErrors}
                    onChange={(evt) => {
                      setIgnoreErrors(evt.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Ignore Errors"
              />
            )}
          </Box>
        </Box>
      )}
      {!!rowTips.length && (
        <Box
          sx={{
            mt: 2,
            maxHeight: '120px',
            overflow: 'auto',
          }}
        >
          {rowTips.map((row) => {
            return (
              <Alert severity="error" key={row.key + row.index}>
                Invalid value: row [{row.index}] {row.key} {row.val} [{row.msg}]
              </Alert>
            );
          })}
        </Box>
      )}
    </>
  );
};

StickyTips.displayName = 'StickyTips';

export default forwardRef(StickyTips);
