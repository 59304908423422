import { Box, Tab, Tabs, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BulkEditPolicyAgentSplit from '@/components/ToolsPage/BulkEditPolicyAgentSplit';
import CsvToJson from '@/components/ToolsPage/CsvToJson';
import GenericTool from '@/components/ToolsPage/GenericTool';
import JsonToCsv from '@/components/ToolsPage/JsonToCsv';
import PaymentLinks from '@/components/ToolsPage/PaymentLinks';
import VerifyUserAccount from '@/components/ToolsPage/VerifyUserAccount';

const uniques = (strs, delimiter = '\n') =>
  Array.from(new Set(strs.split(delimiter))).join('\n');

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ pt: 2 }}>
        <Typography component="div">{children}</Typography>
      </Box>
    )}
  </div>
);

const tabs = [
  {
    label: 'Unique lines',
    path: 'unique-lines',
    component: <GenericTool outFn={uniques} />,
  },
  {
    label: 'Nano Ids',
    path: 'nano-ids',
    component: (
      <GenericTool
        outFn={(num) =>
          Array.from(
            new Array(
              Number.isInteger(Number.parseInt(num)) ? Number.parseInt(num) : 1
            )
          )
            .map(() => nanoid())
            .join('\n')
        }
      />
    ),
  },
  {
    label: 'CSV to JSON',
    path: 'csv-to-json',
    component: <CsvToJson />,
  },
  {
    label: 'JSON to CSV',
    path: 'json-to-csv',
    component: <JsonToCsv />,
  },
  {
    label: 'Payment links',
    path: 'payment-links',
    component: <PaymentLinks />,
  },
  {
    label: 'Verify user',
    path: 'verify-user',
    component: <VerifyUserAccount />,
  },
  {
    label: 'Agent split import',
    path: 'agent-split-import',
    component: <BulkEditPolicyAgentSplit />,
  },
];

const ToolsPage = ({}) => {
  const { tab = 'list' } = useParams();
  const tabPaths = tabs.map((tab) => tab.path);
  const routedTab = tabPaths.indexOf(tab) >= 0 ? tabPaths.indexOf(tab) : 0;
  const [value, setValue] = useState(routedTab);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    navigate(`/admin/tools/${tabs[newValue].path}`);
    setValue(newValue);
  };

  return (
    <Box p={2} sx={{ width: 1, overflowY: 'scroll' }}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            {tabs.map((tab) => (
              <Tab label={tab.label} key={tab.label} />
            ))}
          </Tabs>
        </Box>
        {tabs.map((tab) => (
          <TabPanel value={value} index={tabs.indexOf(tab)} key={tab.label}>
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

ToolsPage.propTypes = {
  user: PropTypes.object,
  openSnackbar: PropTypes.func,
};

export default ToolsPage;
