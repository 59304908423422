import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import API from '@/services/API';
import { FieldTypes, Roles } from '@/types';
import EnhancedDataView from '../organisms/EnhancedDataView';
import Statements from '@/services/Statements';
import Reconciliations from '@/services/Reconciliations';
import Reports from '@/services/Reports';

const ViewsFieldsSettings = () => {
  const statements = new Statements('insurance');
  const reconciliations = new Reconciliations('insurance', '');
  const reports = new Reports('insurance', {});

  const [companiesOptions, setCompaniesOptions] = useState<
    { id: string; label: string }[]
  >([]);
  const [companyValue, setCompanyValue] = useState<string[]>([]);

  const { data: settingsData, refetch } =
    API.getBasicQuery('accounts/settings');
  const { data: companiesData } = API.getBasicQuery('companies', 'all=1');

  const companySettingsPoster = API.getMutation(
    'accounts/settings/companies',
    'POST'
  );

  useEffect(() => {
    console.log(settingsData);
    if (settingsData) {
      const companies = settingsData.companies_view;
      if (companies) {
        setCompanyValue(companies);
      }
    }
  }, [settingsData]);

  useEffect(() => {
    setCompaniesOptions(
      companiesData
        ?.filter((item) => item.access === 'global')
        .map((item) => ({
          id: item.str_id,
          label: item.company_name,
        }))
    );
  }, [companiesData]);

  const dataSpec = {
    label: 'Views & fields',
    table: 'accounts/settings/views-and-fields',
    fields: {
      custom_fields_id: {
        enabled: false,
        readOnly: true,
        visible: false,
      },
      key: {
        label: 'Page',
        enabled: true,
        type: 'select',
        sx: { width: 200 },
        readOnly: true,
        options: [
          {
            label: 'Reconciliations',
            id: 'reconciliation',
          },
          {
            label: 'Commissions',
            id: 'commissions',
          },
          {
            label: 'Policies',
            id: 'policies',
          },
          {
            label: 'Insights',
            id: 'insights',
          },
          {
            label: 'Upload documents',
            id: 'add_documents',
          },
          {
            label: 'Agents > Agents',
            id: 'agents',
          },
          {
            label: 'Agents > Groups',
            id: 'agents_groups',
          },
          {
            label: 'Agents > Production',
            id: 'agents_production',
          },
          {
            label: 'Schedules > Agents',
            id: 'agent_schedules',
          },
          {
            label: 'Schedules > Carriers',
            id: 'carriers_schedules',
          },
          {
            label: 'Schedules > Incentives',
            id: 'incentives_schedules',
          },
          {
            label: 'Schedules > Comp grids',
            id: 'comp_grids_schedules',
          },
          {
            label: 'Settings > Data processing',
            id: 'settings_data_processing',
          },
          {
            label: 'Views',
            id: 'views',
          },
          {
            label: 'Reports > Reports',
            id: 'reports',
          },
          {
            label: 'Reports > Summaries',
            id: 'summaries',
          },
          {
            label: 'Companies > Companies',
            id: 'companies',
          },
          {
            label: 'Companies > Products',
            id: 'products',
          },
          {
            label: 'Companies > Options',
            id: 'options',
          },
          {
            label: 'Documents',
            id: 'documents',
          },
        ],
      },
      role: {
        label: 'Role',
        enabled: true,
        type: 'select',
        options: [
          {
            label: 'Account admin',
            id: Roles.ACCOUNT_ADMIN,
          },
          {
            label: 'Producer',
            id: Roles.PRODUCER,
          },
          {
            label: 'Data specialist',
            id: Roles.DATA_SPECIALIST,
          },
        ],
        readOnly: true,
      },
      menu_label: {
        label: 'Menu label',
        enabled: true,
      },
      page_label: {
        label: 'Page label',
        enabled: true,
      },
      show_page: {
        label: 'Enable',
        type: 'boolean',
        enabled: true,
      },
      read_only: {
        label: 'Read only',
        type: 'boolean',
        enabled: true,
      },
      fields: {
        label: 'Fields',
        enabled: true,
        type: FieldTypes.DRAGABLE_SELECT,
        customSelectOptions: {
          statements: statements,
          reconciliations: reconciliations,
          reports: reports,
        },
        tableFormatter: (field, row, dynamicSelects, headers) => {
          if (field && Array.isArray(field)) {
            return field
              .map(
                (f) =>
                  headers.customSelectOptions[row.custom_fields_id]?.fields[f]
                    ?.label || f
              )
              .map((label, index) => (
                <Chip
                  key={`${row.custom_fields_id}-${row.role}-${label}-${index}`}
                  sx={{ mt: 0.5, mr: 0.25 }}
                  label={label}
                />
              ));
          }
          return null;
        },
      },
      outstandingMobileFields: {
        label: 'Mobile fields',
        enabled: true,
        type: FieldTypes.DRAGABLE_SELECT,
        customSelectOptions: {
          statements: statements,
          reconciliations: reconciliations,
          reports: reports,
        },
        tableFormatter: (field, row, dynamicSelects, headers) => {
          if (field && Array.isArray(field)) {
            return field
              .map(
                (f) =>
                  headers.customSelectOptions[row.custom_fields_id]?.fields[f]
                    ?.label || f
              )
              .map((label, index) => (
                <Chip
                  key={`${row.custom_fields_id}-${row.role}-${label}-${index}`}
                  sx={{ mt: 0.5, mr: 0.25 }}
                  label={label}
                />
              ));
          }
          return null;
        },
      },
      default_filters: {
        label: 'Default filters',
        type: FieldTypes.CUSTOM,
        customFieldsOptions: {
          statements: [
            {
              label: 'Compensation type - Commission',
              id: 'compensation_type::Commission',
            },
          ],
        },
        enabled: true,
        render: (field, row, setter) => (
          <FormControl sx={{ mt: 1, mb: 1 }}>
            <InputLabel id={`${field.label}-label`}>{field.label}</InputLabel>
            <Select
              labelId={`${field.label}-label`}
              label={field.label}
              value={row.default_filters || 'Add filter'}
              onChange={(event) => {
                const selectedFilterId = event.target.value;
                setter({
                  ...row,
                  default_filters: selectedFilterId,
                });
              }}
            >
              <MenuItem value="Add filter">Add filter</MenuItem>
              {(field.customFieldsOptions[row.custom_fields_id] || []).map(
                (filter: { id: string; label: string }) => (
                  <MenuItem key={filter.id} value={filter.id}>
                    {filter.label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        ),
        tableFormatter: (field, row, dynamicSelects, headers) => {
          if (field) {
            return field.map((fieldElement) => {
              const selectedValue = headers.customFieldsOptions[
                row.custom_fields_id
              ]?.filter((filter) => {
                return filter.id === fieldElement;
              });
              const selectedLabel = selectedValue[0]?.label;
              return (
                <Chip
                  key={fieldElement}
                  sx={{ mt: 0.5, mr: 0.5 }}
                  label={selectedLabel}
                />
              );
            });
          }
          return null;
        },
      },
      page_options: {
        label: 'Page options',
        enabled: true,
      },
    },
  };

  return (
    <>
      <EnhancedDataView
        dataSpec={dataSpec}
        hideSelectedCount
        hideExport={true}
        hideAdd={true}
        enableMultiSelect={false}
      />
      <Box sx={{ width: '90%', mt: 2, ml: 1 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Companies available
        </Typography>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box sx={{ flex: 1, ml: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography>Companies</Typography>
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '1024px',
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="id-extract">Companies</InputLabel>
                  <Select
                    onChange={(e) =>
                      setCompanyValue(e.target.value as string[])
                    }
                    id="id-companies"
                    fullWidth
                    multiple
                    label="Companies"
                    value={companyValue}
                  >
                    {companiesOptions?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.label}
                      </MenuItem>
                    )) || []}
                  </Select>
                </FormControl>
                <Button
                  sx={{ mt: 2, width: 80, alignSelf: 'flex-end' }}
                  variant="contained"
                  onClick={async () => {
                    companySettingsPoster.mutateAsync({
                      companies_view: companyValue,
                      companyUpdate: true,
                      // When updating the company settings the role is not important since we will update the settings for all roles
                    });
                    refetch();
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewsFieldsSettings;
