import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Edit } from '@mui/icons-material';

import Spreadsheet from '@/services/Spreadsheet';
import {
  ShareDataModel,
  RowMappingModel,
  RangeDataModel,
  ProcessDataModel,
} from './process';
import { ProcessFormModel } from './model';
import { DocumentProcessTypes, DocumentProcessActionTypes } from '@/types';

interface ProcessMappingFormModel {
  selectedDataRange: any;
  rangeData: RangeDataModel[];
  processForm: ProcessFormModel;
  processedData: ProcessDataModel;
  shareData: ShareDataModel;
  setRowMapping: (rowMapping: any) => void;
  rowMapping: RowMappingModel;
  setSelectedDataRange: (range: any) => void;
  addActionCount: (type?: DocumentProcessTypes) => void;
}

const ProcessMappingForm: FC<ProcessMappingFormModel> = ({
  selectedDataRange,
  rangeData,
  shareData,
  setRowMapping,
  rowMapping,
  setSelectedDataRange,
  processForm,
  processedData,
  addActionCount,
}) => {
  return (
    <Box
      sx={{
        width: 288,
        mr: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {processForm.method === 'mapping' ? (
          <FormControl sx={{ mt: 1, flex: 1 }}>
            <InputLabel id="select-row">Spreadsheet header row</InputLabel>
            <Select
              id="select-row"
              label="Spreadsheet header row"
              value={selectedDataRange?.index}
              onChange={(e) => {
                addActionCount(DocumentProcessActionTypes.EDIT_MAPPING);
                setSelectedDataRange(
                  rangeData.filter(
                    (a) =>
                      a.index === Number.parseInt(e?.target?.value || 0, 10)
                  )[0]
                );
                setRowMapping({});
              }}
            >
              {rangeData.length === 0 && <MenuItem value={0}>&nbsp;</MenuItem>}
              {Object.values(rangeData).map((row) => (
                <MenuItem value={row.index} key={row.index}>
                  <span style={{ width: 800, overflow: 'auto' }}>
                    {`Row ${row.index + 1}: ${row.fields
                      .filter((f) => f !== null && f !== undefined)
                      .join(', ')}`}
                  </span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <FormControl sx={{ mt: 1, flex: 1 }}>
            <InputLabel id="select-row">Header row</InputLabel>
            <Select id="select-row" label="Header row" value={0}>
              <MenuItem value={0} key={0}>
                <span style={{ width: 800, overflow: 'auto' }}>
                  {`Row 1: ${processedData.fields
                    .filter((f) => f !== null && f !== undefined)
                    .join(', ')}`}
                </span>
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Box>
      <Divider sx={{ mt: 2 }} variant="middle" textAlign="center" />
      <Typography sx={{ fontWeight: 500 }}>Map document columns</Typography>
      <Box
        sx={{
          flex: 1,
          height: '100%',
          display: 'flex',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            height: '100%',
            overflow: 'scroll',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          {Object.entries(shareData.fields)
            .sort(([_a, va], [_b, vb]) => {
              if (va.required && !vb.required) return -1;
              else if (!va.required && vb.required) return 1;
              else return va.label.localeCompare(vb.label);
            })
            .map(([k, v]) => (
              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
                key={k}
              >
                <FormControl sx={{ textAlign: 'left' }}>
                  <InputLabel>
                    {v.label}
                    {v.required ? ' *' : ''}
                  </InputLabel>
                  <Select
                    label={`${v.label}${v.required ? ' *' : ''}`}
                    id={`select-${k}`}
                    sx={{ width: 270 }}
                    onChange={(e) => {
                      const newRowMapping = {
                        ...rowMapping,
                        [k]:
                          e.target.value === 'MANUAL_ENTRY'
                            ? ''
                            : e.target.value,
                      };
                      if (e.target.value === 'NULL') {
                        Reflect.deleteProperty(newRowMapping, k);
                      }
                      addActionCount(DocumentProcessActionTypes.EDIT_MAPPING);
                      setRowMapping(newRowMapping);
                    }}
                    value={
                      typeof rowMapping?.[k] === 'string'
                        ? 'MANUAL_ENTRY'
                        : (String(rowMapping?.[k]) ?? '')
                    }
                    disabled={processForm.mapping !== 0}
                  >
                    <MenuItem value={'NULL'} sx={{ color: 'gray' }}>
                      (None)
                    </MenuItem>
                    {<MenuItem value="MANUAL_ENTRY">[Manual entry]</MenuItem>}
                    <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                    {selectedDataRange?.fields?.map((userField, i) => (
                      <MenuItem
                        value={i}
                        key={i}
                        disabled={Object.values(rowMapping).includes(i)}
                      >
                        {`Col ${Spreadsheet.indexToCol(i)}: ${userField}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {typeof rowMapping?.[k] === 'string' && (
                  <Box sx={{ width: 270, display: 'flex', mt: 1, mb: 1 }}>
                    {!v.matches?.length ? (
                      <TextField
                        id={`${v.key}-manual-entry`}
                        label={`${v.label} (manual entry)`}
                        variant="outlined"
                        sx={{
                          width: '100%',
                          border: '1px dashed #ccc',
                          borderRadius: 50,
                        }}
                        value={rowMapping[k] || ''}
                        onChange={(e) => {
                          const newRowMapping = {
                            ...rowMapping,
                            [k]: e.target.value,
                          };
                          addActionCount(
                            DocumentProcessActionTypes.EDIT_MAPPING
                          );
                          setRowMapping(newRowMapping);
                        }}
                        InputProps={{
                          endAdornment: <Edit />,
                        }}
                      />
                    ) : (
                      <FormControl sx={{ width: '100%' }}>
                        <InputLabel>{`${v.label} (manual entry)`}</InputLabel>
                        <Select
                          id={`${v.key}-manual-entry`}
                          label={`${v.label} (manual entry)`}
                          variant="outlined"
                          value={rowMapping[k] || ''}
                          sx={{ border: '1px dashed #ccc' }}
                          onChange={(e) => {
                            const newRowMapping = {
                              ...rowMapping,
                              [k]: e.target.value,
                            };
                            addActionCount(
                              DocumentProcessActionTypes.EDIT_MAPPING
                            );
                            setRowMapping(newRowMapping);
                          }}
                        >
                          <MenuItem value={'NULL'} sx={{ color: 'gray' }}>
                            (None)
                          </MenuItem>
                          {v.matches?.map((userField, i) => (
                            <MenuItem value={userField} key={userField}>
                              {userField}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                )}
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ProcessMappingForm;
