import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import validator from 'validator';

import DynamicSelect from '@/components/DataForm/DynamicSelect';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';

type CompProfilesAddProps = {
  data: any;
  setter: any;
  field: any;
  dynamicSelects: any;
  readOnly: boolean;
  table: string;
};

const applyFunc = (func, arg) => (typeof func === 'function' ? func(arg) : arg);

const CompProfilesAdd: React.FC<CompProfilesAddProps> = ({
  data,
  field,
  setter,
  dynamicSelects,
  readOnly = false,
  table,
}) => {
  const { selectedAccount } = useAccountStore();
  const [query, setQuery] = useState('');
  const [visibleProfilesCount, setVisibleProfilesCount] = useState(5);

  const profilesWithDates = data?.[table] ?? [];

  profilesWithDates.forEach((profileWithDates) => {
    if (!profileWithDates.contact_id.toString().includes('::'))
      profileWithDates.contact_id =
        profileWithDates.contact_id + '::' + profileWithDates.str_id;
  });

  const showMoreProfiles = () => {
    setVisibleProfilesCount((prevCount) => prevCount + 10);
  };

  return (
    <Box>
      <Typography variant="subtitle2">{field.label}</Typography>
      <Box
        key={`${field.id}-box`}
        sx={{
          width: '100%',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            pl: 0.5,
            py: 0.5,
            display: 'flex',
            flexDirection: 'column',
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            width: '100%',
            backgroundColor: '#2196f308',
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {profilesWithDates
              .slice(0, visibleProfilesCount)
              .map((profileWithDates) => (
                <Box
                  key={profileWithDates.contact_id}
                  sx={{
                    m: 0.5,
                    p: 1,
                    borderStyle: 'solid',
                    borderColor: 'silver',
                    borderWidth: 1,
                    borderRadius: 4,
                    display: 'inline-block',
                    width: 180,
                    backgroundColor: '#2196f30a',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    position="relative"
                    sx={{ ml: 0.5 }}
                  >
                    <Typography variant="body2">
                      {Formatter.contact(profileWithDates.contact, {
                        account_id: selectedAccount?.accountId,
                      })}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setter({
                          ...data,
                          [table]: data[table].filter(
                            (item) =>
                              item.contact_id !== profileWithDates.contact_id
                          ),
                        });
                      }}
                      disabled={readOnly}
                      sx={{ p: 0 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box>
                    <BasicDatePicker
                      label="Start date"
                      value={profileWithDates.start_date}
                      setValue={(e) => {
                        setter({
                          ...data,
                          [table]: data[table].map((item) =>
                            item.contact_id === profileWithDates.contact_id
                              ? { ...item, start_date: e }
                              : item
                          ),
                        });
                      }}
                      sx={{ my: 0.5, width: 160 }}
                      disabled={readOnly}
                    />
                  </Box>
                  <Box>
                    <BasicDatePicker
                      label="End date"
                      value={profileWithDates.end_date}
                      setValue={(e) => {
                        setter({
                          ...data,
                          [table]: data[table].map((item) =>
                            item.contact_id === profileWithDates.contact_id
                              ? { ...item, end_date: e }
                              : item
                          ),
                        });
                      }}
                      sx={{ my: 0.5, width: 160 }}
                      disabled={readOnly}
                    />
                  </Box>
                  <Box>
                    <TextField
                      label="Multiplier"
                      value={profileWithDates.multiplier ?? '100'}
                      sx={{ mt: 0.5, width: 160 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ ml: 0 }}>
                            %
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setter({
                          ...data,
                          [table]: data[table].map((item) =>
                            item.contact_id === profileWithDates.contact_id
                              ? {
                                  ...item,
                                  multiplier: e.target.value,
                                }
                              : item
                          ),
                        });
                      }}
                      error={
                        profileWithDates.multiplier &&
                        !validator.isFloat(profileWithDates.multiplier.trim(), {
                          min: 0,
                          max: 1000,
                        })
                      }
                      helperText={
                        profileWithDates.multiplier &&
                        !validator.isFloat(profileWithDates.multiplier.trim(), {
                          min: 0,
                          max: 1000,
                        })
                          ? 'Must be a number between 0 and 1000'
                          : ''
                      }
                      disabled={readOnly}
                    />
                  </Box>
                </Box>
              ))}
            {profilesWithDates.length > visibleProfilesCount && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 0.75,
                  mr: 1,
                }}
              >
                <Box>
                  <Button onClick={showMoreProfiles} variant="outlined">
                    Show more (
                    {`${profilesWithDates.length - visibleProfilesCount}`})
                  </Button>
                </Box>
              </Box>
            )}
            <DynamicSelect
              field={{
                id: 'contact',
                label: 'Agents',
                table: 'contacts',
                multiple: true,
                formatter: Formatter.getDynamicSelectFormatter((option) => {
                  return `${Formatter.contact(option, { incl_email: true, account_id: selectedAccount?.accountId })}${Array.isArray(option?.contact_level) && option?.contact_level.length > 0 ? ` • ${option?.contact_level.map((l) => l.level_label).join(', ')}` : ''}`;
                }),
                optionFormatter: (option) => {
                  return `${Formatter.contact(option, { incl_email: true, account_id: selectedAccount?.accountId })}${Array.isArray(option?.contact_level) && option?.contact_level.length > 0 ? ` • ${option?.contact_level.map((l) => l.level_label).join(', ')}` : ''}`;
                },
                optionValuer: (o) => o.id,
                type: 'dynamic-select',
              }}
              setNewData={(e) => {
                let newIds = [];
                if (e instanceof Event) {
                  // @ts-ignore
                  newIds = e?.target?.value;
                } else if (Array.isArray(e)) {
                  // @ts-ignore
                  newIds = e;
                }
                if (Array.isArray(newIds)) {
                  setter({
                    ...data,
                    [table]: [
                      ...(data[table] ?? []),
                      ...newIds.map((newId) => ({
                        contact: dynamicSelects.find(
                          (item) => item.id === newId
                        ),
                        contact_id: newId + '::' + nanoid(),
                        start_date: null,
                        end_date: null,
                        multiplier: '100',
                      })),
                    ],
                  });
                }
              }}
              dynamicSelects={{ contacts: dynamicSelects }}
              dataFormMode={false}
              addMode={true}
              sx={{ m: 0.5 }}
            />
            <Box sx={{ mt: 0.5 }}>
              <Button
                onClick={() => {
                  setter({
                    ...data,
                    [table]: [],
                  });
                }}
              >
                Clear
              </Button>
            </Box>
            {/* <FormControl
              key={field.id}
              sx={{ m: 0.5, width: 145 }}
              disabled={readOnly}
            >
              <InputLabel id={`${field.id}-label`}>Add</InputLabel>
              <Select
                labelId={`${field.id}-label`}
                id={field.id}
                label="Add"
                value={''}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  // @ts-ignore
                  const newId = e.target.value?.id;
                  if (newId) {
                    setter({
                      ...data,
                      [table]: [
                        ...(data[table] ??
                          []),
                        {
                          contact: dynamicSelects.find(
                            (item) => item.id === newId
                          ),
                          contact_id: newId + '::' + nanoid(),
                          start_date: null,
                          end_date: null,
                          multiplier: '100',
                        },
                      ],
                    });
                  }
                }}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#333',
                  },
                  width: '50%',
                }}
              >
                <Box
                  sx={{
                    mb: 0.5,
                    mx: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <TextField
                    sx={{ flex: 1 }}
                    label="Search"
                    value={query}
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                  />
                </Box>
                {field.nullable && (
                  <MenuItem value={''} key="null">
                    &nbsp;
                  </MenuItem>
                )}
                {dynamicSelects
                  ?.filter((item) =>
                    (
                      [
                        item?.email,
                        item?.first_name,
                        item?.last_name,
                        ...(item?.contact_level ?? []).map(
                          (l) => `level:${l.level_label}`
                        ),
                      ]
                        .join(' ')
                        .toLowerCase() ?? ''
                    ).includes(query.toLowerCase())
                  )
                  ?.sort((a, b) =>
                    Formatter.contact(a) < Formatter.contact(b) ? -1 : 1
                  )
                  ?.map((option) => (
                    <MenuItem
                      value={option}
                      key={applyFunc(field.optionValuer, option)}
                    >
                      {`${Formatter.contact(option, { incl_email: true })}${Array.isArray(option?.contact_level) && option?.contact_level.length > 0 ? ` • ${option?.contact_level.map((l) => l.level_label).join(', ')}` : ''}`}
                    </MenuItem>
                  )) ?? null}
              </Select>
            </FormControl> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompProfilesAdd;
