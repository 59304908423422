import { useEffect, useState, ReactNode } from 'react';
import { Autocomplete, TextField, Checkbox, ListItemText } from '@mui/material';

interface SelectItem {
  [key: string]: any;
}

interface FilterSelectItem {
  value: string;
  label: string;
  [key: string]: any;
  data: SelectItem;
}

interface FilterSelectProps {
  options: SelectItem[];
  getOptionLabel?: (option: any, options?: any[]) => string;
  onChange: any;
  label?: string;
  multiple?: boolean;
  labelKey?: string;
  valueKey?: string;
  renderOptionItem?: (option: SelectItem) => ReactNode;
  [key: string]: any;
}

const FilterSelect = ({
  options,
  getOptionLabel,
  onChange,
  label,
  multiple,
  renderOptionItem,
  labelKey = 'label',
  valueKey = 'value',
  placeholder = 'Select options',
  loading = false,
  ...props
}: FilterSelectProps) => {
  const [selectOptions, setSelectOptions] = useState<FilterSelectItem[]>([]);

  useEffect(() => {
    const list = options.map((option) => {
      const value = option[valueKey];
      const label = option[labelKey];
      return {
        value,
        label,
        data: option,
      };
    });
    setSelectOptions(list);
  }, [options]);

  return (
    <Autocomplete
      multiple={multiple}
      filterSelectedOptions={multiple}
      options={selectOptions}
      onChange={(event, value) => onChange(value as any)}
      getOptionLabel={(option) =>
        typeof getOptionLabel === 'function'
          ? getOptionLabel(option, options)
          : ''
      }
      loading={loading}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.value}>
            {multiple && <Checkbox checked={selected} />}
            <ListItemText
              key={option.value}
              primary={
                renderOptionItem
                  ? renderOptionItem(option.data)
                  : getOptionLabel
                    ? getOptionLabel(option.data, selectOptions)
                    : option.label
              }
            />
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      {...props}
    />
  );
};

export default FilterSelect;
