import { LoadingButton } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import API from '@/services/API';

const VerifyUserAccount: React.FC = () => {
  const [userUid, setUserUid] = useState('');
  const [loading, setLoading] = useState(false);

  const poster = API.getMutation('admin/users/verify_user_account', 'POST');

  const { openSnackbar } = useOutletContext() as any;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <TextField
        label="User uid"
        value={userUid}
        onChange={({ target: { value } }) => setUserUid(value)}
        sx={{ width: 400, mr: 1 }}
      />
      <LoadingButton
        loading={loading}
        variant="outlined"
        onClick={async () => {
          setLoading(true);
          const response = await poster.mutateAsync({
            userUid: userUid,
          });
          if (response.error) {
            openSnackbar(response.error);
          } else {
            openSnackbar('User email verified');
          }
          setLoading(false);
        }}
        sx={{ mr: 1 }}
      >
        Verify email
      </LoadingButton>
    </Box>
  );
};

export default VerifyUserAccount;
