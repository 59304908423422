import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
} from '@mui/material';

import API from '@/services/API';

interface RemoveFieldsDialogProp {
  open: boolean;
  onClose: (v) => void;
  onConfirm: (e: string) => void;
  json: string;
  extraction?: {
    id: number;
    str_id: string;
    method: string;
  };
}

const VertexResult = ({
  open,
  onClose,
  onConfirm,
  json,
  extraction,
}: RemoveFieldsDialogProp) => {
  const extractionPutter = API.getMutation('extractions', 'PUT');
  const [formData, setFormData] = useState<{
    jsonStr?: string;
    jsonRes?: string;
  }>({});

  useEffect(() => {
    if (json) {
      setFormData({
        jsonRes: '',
        jsonStr: json,
      });
    }
  }, [json]);

  const onFormChange = (
    key: 'jsonStr' | 'jsonRes',
    value: string | undefined
  ) => {
    setFormData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    if (formData.jsonStr) {
      try {
        const res = JSON.parse(formData.jsonStr);
        onFormChange('jsonRes', JSON.stringify(res, null, 2));
      } catch (error) {
        onFormChange('jsonRes', error as string);
      }
    }
  }, [formData.jsonStr]);

  const handleSubmit = () => {
    // If exist extractionId, update extraction
    if (extraction && extraction.id) {
      try {
        const output = JSON.stringify(JSON.parse(formData?.jsonRes || ''));
        const poster = [
          JSON.stringify({
            role: 'model',
            parts: [
              {
                text: output,
              },
            ],
          }),
        ];
        extractionPutter.mutateAsync({
          id: extraction.id,
          output: JSON.stringify(poster),
        });
      } catch (error) {
        console.error(`An error ocurred when updating extraction: ${error}`);
      }
    }
    onConfirm(formData?.jsonRes || '');
  };
  return (
    <Dialog open={open} onClose={onClose} fullScreen sx={{ p: 10 }}>
      <DialogTitle>Invalid JSON Result</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            width: '90vw',
            height: '100%',
            p: 2,
            gap: 2,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl fullWidth sx={{ mb: 2, height: '100%' }}>
              <TextField
                label="JSON"
                variant="outlined"
                multiline
                minRows={26}
                value={formData?.jsonStr}
                onChange={(e) => {
                  onFormChange('jsonStr', e.target.value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormControl fullWidth sx={{ mb: 2, height: '100%' }}>
              <TextField
                label="Result"
                variant="outlined"
                multiline
                minRows={26}
                value={formData?.jsonRes}
              />
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VertexResult;
