import { parseDate } from 'chrono-node';

const removeColsWithName = (array: string[][], colNames: string[]) => {
  const indiciesToRemove: number[] = [];
  const headers = array[0];
  colNames.forEach((colName) => {
    const idx = headers.indexOf(colName);
    if (idx >= 0) indiciesToRemove.push(idx);
    else console.warn(`Could not find a column named ${colName} in header row`);
  });
  indiciesToRemove.sort((a, b) => b - a); // Work backwards so indicies don't change
  const newArray = JSON.parse(JSON.stringify(array));
  indiciesToRemove.forEach((idx) => {
    newArray.forEach((row) => row.splice(idx, 1));
  });
  return newArray;
};

const mapToArray = (obj: any) => {
  return Object.values(obj).map((item: any) =>
    typeof item === 'object' ? Object.values(item) : item
  );
};

const pickData = (mapObj, arr) => {
  const [headerConf, ...mainData] = arr;
  const tableHeaders: any = [];
  const tableData: any = [];

  Object.entries(mapObj).forEach(([k, v], i) => {
    const titleIndex = headerConf.findIndex((title) => title.startsWith(k));
    if (titleIndex > -1) {
      tableHeaders.push(v);

      mainData.forEach((row, idx) => {
        const temp = row[titleIndex];
        if (tableData[idx]) {
          tableData[idx].push(temp);
        } else {
          tableData[idx] = [temp];
        }
      });
    }
  });

  return {
    tableHeaders,
    tableData,
  };
};

const findTextLine = (json, str) => {
  let txt = '';
  json.Lines?.find((lineItem) => {
    const item = lineItem.LinesArray.find((line) => line.Line.includes(str));
    if (item) {
      txt = item.Line;
    }
    return !!item;
  });
  return txt;
};
const findTextLineIndex = (json, str) => {
  const txt: number[] = [];
  json.Lines?.find((lineItem, index) => {
    const _idx = lineItem.LinesArray.findIndex((line) =>
      line.Line.includes(str)
    );
    if (_idx > -1) {
      txt.push(index, _idx);
    }
    return _idx > -1;
  });
  return txt;
};

const findTextLineByIndex = (json, indexArr) => {
  return json.Lines[indexArr[0]].LinesArray[indexArr[1]].Line;
};

const findAllTextLine = (json, str) => {
  const txtList: any = [];
  json.Lines?.filter((lineItem: any) => {
    const item = lineItem.LinesArray.find((line) => line.Line.includes(str));
    if (item) {
      txtList.push(item.Line);
    }
    return !!item;
  });
  return txtList;
};

const findTextLineAllIndex = (json, searchText) => {
  const allIndices: any = [];
  for (let i = 0; i < json.Lines.length; i++) {
    const linesArray = json.Lines[i].LinesArray;
    for (let j = 0; j < linesArray.length; j++) {
      if (linesArray[j].Line.includes(searchText)) {
        allIndices.push([i, j]);
      }
    }
  }
  return allIndices;
};

const findAllTextLineByIndex = (json, indicesArray) => {
  const resultLines: any = [];

  for (const indices of indicesArray) {
    if (indices.length !== 2) {
      // 索引数组中的每个元素必须是长度为2的数组
      resultLines.push(null);
      continue;
    }

    const [lineIndex, arrayIndex] = indices;
    if (json.Lines[lineIndex] && json.Lines[lineIndex].LinesArray[arrayIndex]) {
      // 检查索引是否在数据结构的范围内
      resultLines.push(json.Lines[lineIndex].LinesArray[arrayIndex].Line);
    } else {
      resultLines.push(null); // 如果索引无效，加入 null
    }
  }

  return resultLines;
};

const convertToNumber = (str) => {
  if (!str) return 0;
  const numberString = str
    .replace(/[^0-9.-]+/g, '')
    ?.replace(/\(([^)]+)\)/, '-$1');
  const numberValue = parseFloat(numberString);
  return numberValue;
};

const getPercent = (value1, value2) => {
  const number1 = convertToNumber(value1);
  const number2 = convertToNumber(value2);
  if (number2 === 0) return '';
  const percent = ((number1 / number2) * 100).toFixed(2);
  return !Number.isFinite(percent) && !Number.isNaN(percent)
    ? `${percent}%`
    : '';
};
/**
 * Splice excel array
 */
const spliceExcelArray = (
  dataList: string[][],
  companyList: string[],
  tableDataTarget: string[]
): { [key: string]: string[][] } => {
  const result: { [key: string]: string[][] } = {};
  let currentCompany: string | null = null;
  let recording = false;
  let tempData: string[][] = [];
  let expectedColumnCount = 0;
  dataList.forEach((row) => {
    // const isCompanyRow = row.some((cell) => {
    //   const cellValue = replaceNewLinesAndTrim(cell);
    //   return companyList.includes(cellValue);
    // });
    const isCompanyRow = companyList.includes(row[0]);
    if (isCompanyRow) {
      if (currentCompany && tempData.length > 0) {
        result[currentCompany] = tempData;
      }
      currentCompany = row[0];
      tempData = [];
      recording = false;
      expectedColumnCount = 0;
    } else if (tableDataTarget.some((target) => row.includes(target))) {
      recording = true;
      tempData.push(row);
      expectedColumnCount = row.length;
    } else if (recording && row.length === expectedColumnCount) {
      tempData.push(row);
    }
  });
  if (currentCompany && tempData.length > 0) {
    result[currentCompany] = tempData;
  }

  return result;
};

type DateFormats = 'YYYYMMDD' | 'MMDDYYYY' | 'MMDDYY' | 'MM/YY' | 'MM-DD';

const splitFormatDate = (
  dateString: string,
  format: DateFormats,
  defaultYear = '2023'
) => {
  let year, month, day;
  switch (format) {
    case 'YYYYMMDD':
      year = dateString.substring(0, 4);
      month = dateString.substring(4, 6);
      day = dateString.substring(6, 8);
      break;
    case 'MMDDYYYY':
      month = dateString.substring(0, 2);
      day = dateString.substring(2, 4);
      year = dateString.substring(4, 8);
      break;
    case 'MMDDYY':
      month = dateString.substring(0, 2);
      day = dateString.substring(2, 4);
      year = '20' + dateString.substring(4, 6);
      break;
    case 'MM/YY':
      month = dateString.substring(0, 2);
      year = '20' + dateString.substring(3, 5);
      day = '01'; // 默认为当月第一天
      break;
    case 'MM-DD':
      month = dateString.substring(0, 2);
      day = dateString.substring(3, 5);
      year = defaultYear;
      break;
    default:
      throw new Error('Unsupported date format');
  }

  if (!day) {
    day = '01';
  }

  month = month.padStart(2, '0');
  day = day.padStart(2, '0');

  return `${month}/${day}/${year}`;
};

const convertArrayToMap = (keys, valuesArray) => {
  return valuesArray.map((values) => {
    const obj = {};
    keys.forEach((key, index) => {
      obj[key] = values[index];
    });
    return obj;
  });
};

const convertMapToArray = (objects) => {
  if (!objects || !objects.length) {
    return { keys: [], valuesArray: [] };
  }
  const keys = Object.keys(objects[0]);

  const valuesArray = objects.map((obj) => keys.map((key) => obj[key]));

  return { keys, values: valuesArray };
};

const stringToColor = (string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

export const stringAvatar = (name) => {
  if (!name) return {};
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
};

const convertObjectListToArrayList = (objectList) => {
  const keys = Object.keys(objectList[0]);
  const keyMap = {};
  keys.forEach((key, index) => {
    keyMap[key] = index;
  });

  const arrayList = objectList.map((obj) => {
    return keys.map((key) => obj[key]);
  });

  return { arrayList, keyMap };
};

/**
 * Tool funtion list
 */
export const tool = {
  removeColsWithName,
  mapToArray,
  spliceExcelArray,
  parseDate,
  pickData,
  findTextLine,
  findAllTextLine,
  convertToNumber,
  getPercent,
  splitFormatDate,
  convertArrayToMap,
  convertMapToArray,
  findTextLineIndex,
  findTextLineByIndex,
  findTextLineAllIndex,
  findAllTextLineByIndex,
  convertObjectListToArrayList,
};

const desc_removeColsWithName = `
 Remove columns from a 2D array based on the column names provided.
 eg: removeColsWithName([['a', 'b', 'c'], [1, 2, 3], [4, 5, 6]], ['a', 'c']) => [['b'], [2], [5]]
`;
const desc_parseDate = `
  Parse date string (with nature language) to date object
  eg: 
    parseDate('2021-01-01') => Date object;
    parseDate('2021-01-01', 'YYYY-MM-DD') => Date object;
    parseDate('Today is May 25, 2024') => Date object;
`;
const desc_findTextLine = `
  Find the first line that contains the given text in the JSON object.
  eg: 
  findTextLine(json, 'text') => 'text';
`;

const desc_findAllTextLine = `
  Find all lines that contain the given text in the JSON object.
  eg: 
    findAllTextLine(json, 'text') => ['text1', 'text2'];
`;

const desc_convertToNumber = `
  Convert a string to a number.
  eg: 
    convertToNumber('123') => 123;
    convertToNumber('123.45') => 123.45;
    convertToNumber('($123)') => -123;
`;

const desc_getPercent = `
  Get the percentage of two numbers.
  eg: 
    getPercent('123', '456') => '26.97%';
`;

const desc_splitFormatDate = `
  Split a date string to a specific format.
  eg: 
    splitFormatDate('20210101', 'YYYYMMDD') => '01/01/2021';
    splitFormatDate('01012021', 'MMDDYYYY') => '01/01/2021';
    splitFormatDate('010121', 'MMDDYY') => '01/01/2021';
    splitFormatDate('01/21', 'MM/YY') => '01/01/2023';
    splitFormatDate('01-01', 'MM-DD') => '01/01/2023';
`;

const desc_convertArrayToMap = `
  Convert an array of values to a map of objects.
  eg: 
    convertArrayToMap(['a', 'b'], [[1, 2], [3, 4]]) => [{a: 1, b: 2}, {a: 3, b: 4}];
`;

const desc_convertMapToArray = `
  Convert a map of objects to an array of values.
  eg: 
    convertMapToArray([{a: 1, b: 2}, {a: 3, b: 4}]) => {keys: ['a', 'b'], values: [[1, 2], [3, 4]]};
`;

const desc_findTextLineIndex = `
  Find the index of the first line that contains the given text in the JSON object.
  eg: 
  findTextLineIndex(json, 'text') => [0, 1];
`;

const desc_findTextLineByIndex = `
  Find the line that contains the given text in the JSON object by index.
  eg: 
  findTextLineByIndex(json, [0, 1]) => 'text';
`;

const desc_findTextLineAllIndex = `
  Find all indices of lines that contain the given text in the JSON object.
  eg: 
  findTextLineAllIndex(json, 'text') => [[0, 1], [1, 2]];
`;

const desc_findAllTextLineByIndex = `
  Find all lines that contain the given text in the JSON object by index.
  eg: 
  findAllTextLineByIndex(json, [[0, 1], [1, 2]]) => ['text1', 'text2'];
`;

export const toolDesc = {
  removeColsWithName: desc_removeColsWithName,
  parseDate: desc_parseDate,
  findTextLine: desc_findTextLine,
  findAllTextLine: desc_findAllTextLine,
  convertToNumber: desc_convertToNumber,
  getPercent: desc_getPercent,
  splitFormatDate: desc_splitFormatDate,
  convertArrayToMap: desc_convertArrayToMap,
  convertMapToArray: desc_convertMapToArray,
  findTextLineIndex: desc_findTextLineIndex,
  findTextLineByIndex: desc_findTextLineByIndex,
  findTextLineAllIndex: desc_findTextLineAllIndex,
  findAllTextLineByIndex: desc_findAllTextLineByIndex,
};

export type ToolType = typeof tool;

/**
 * Get filename from path
 * @param path
 * @param removeAllNanoId
 */
export const getFilenameFromPath = (path: string, removeAllNanoId = false) => {
  if (!path) return '';
  const filename = path.split('/').pop()?.trim();
  if (!filename) return '';
  if (removeAllNanoId) {
    const nanoIdAllRegex = /[-_a-zA-Z0-9]{21}-/g;
    return filename.replace(nanoIdAllRegex, '');
  }
  return filename.replace(/^[-_a-zA-Z0-9]{21}-/, '');
};
