export enum CompGridRateFields {
  ALL = 'all',
  CARRIER_RATE = 'carrier_rate',
  HOUSE_RATE = 'house_rate',
  TOTAL_RATE = 'total_rate',
}

export interface BulkEditBody {
  ids: number[];
  policy_id?: string;
  agent_name?: string;
  writing_carrier_name?: string;
  carrier_name?: string;
  customer_name?: string;
  product_type?: string;
  product_name?: string;
  effective_date?: string;
  notes?: string;
  contacts?: any;
  agent_commissions_status?: string;
  compensation_type?: string;
  transaction_type?: string;
  policy_status?: string;
  product_option_name?: string;
  processing_date?: string;
  payment_date?: string;
  period_date?: string;
  internal_id?: string;
  split_percentage?: number;
  premium_type?: string;
}

export enum ContactPayableStatuses {
  PAYABLE = 'payable',
  NON_PAYABLE = 'non_payable',
}

export enum ReportReviewStatuses {
  None = '',
  Draft = 'draft',
  Approved = 'approved',
  Rejected = 'rejected',
  InReview = 'in_review',
  RequestUpdate = 'request_update',
  NonPayable = 'non_payable',
}

export enum ReportReviewStatusesLabels {
  None = '',
  Draft = 'Draft',
  Approved = 'Approved',
  Rejected = 'Rejected',
  InReview = 'In review',
  RequestUpdate = 'Request update',
  NonPayable = 'Non-payable',
}

export enum CompReportDateFilter {
  ProcessingDate = 'processing_date',
  PaymentDate = 'payment_date',
}

export enum CompReportPrefixConfig {
  Default = 'default_prefix',
  Custom = 'custom_prefix',
}
