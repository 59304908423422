import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { cloneDeep } from 'lodash';

import CommissionPreview from './CommissionPreview';
import ProcessForm from './ProcessForm';
import ProcessMappingForm from './ProcessMappingForm';
import {
  ShareDataModel,
  ProcessMethodE,
  DocumentTypeE,
  ProcessDataModel,
  RangeDataModel,
  RowMappingModel,
  ProcessResultE,
  ProcessFormKey,
  SpreadSheetProps,
  ProcessorFormatModel,
} from './process.d';
import useCommonData from './hoc/useCommonData';
import { tool } from '@/common/tools';
import API from '@/services/API';
import {
  IDocumentModel,
  IMappingModel,
  IProcessorModel,
  ProcessFormModel,
} from './model';
import { convertFieldListToMapping } from '@/services/helpers';
import { DocumentProcessTypes } from '@/types';

interface CommissionMapperProps {
  file?: File | null;
  fileType?: DocumentTypeE;
  spreadsheet?: SpreadSheetProps | null;
  selectedSheet: string;
  setSelectedSheet: React.Dispatch<React.SetStateAction<string>>;
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
  extraction: any;
  mpData: {
    mappings: IMappingModel[];
    processors: IProcessorModel[];
  };
  setProcessForm: React.Dispatch<React.SetStateAction<ProcessFormModel>>;
  processForm: ProcessFormModel;
  rowData: Partial<IDocumentModel>;
  setProcessFormatData: React.Dispatch<
    React.SetStateAction<ProcessorFormatModel>
  >;
  processFormatData: any;
  fileData: any;
  addActionCount: (type?: DocumentProcessTypes) => void;
}

const CommissionMapper = ({
  file,
  spreadsheet,
  fileType,
  selectedSheet,
  setSelectedSheet,
  errors,
  setErrors,
  extraction,
  mpData,
  setProcessForm,
  processForm,
  rowData,
  setProcessFormatData,
  processFormatData,
  fileData,
  addActionCount,
}: CommissionMapperProps) => {
  const [processedData, setProcessedData] = useState<ProcessDataModel>({
    data: [],
    fields: [],
    version: null,
  });
  const [rangeData, setRangeData] = useState<RangeDataModel[]>([
    {
      index: 0,
      count: 0,
      fields: [],
      data: [],
    },
  ]);

  const [missingRequiredFields, setMissingRequiredFields] = useState<string[]>(
    []
  );

  const [selectedDataRange, setSelectedDataRange] = useState<RangeDataModel>({
    index: 0,
    count: 0,
    fields: [],
    data: [],
  });
  const [rowMapping, setRowMapping] = useState<RowMappingModel>({});

  // Used to store the current processor to trigger get method
  const [curProcessor, setCurProcessor] = useState<IProcessorModel>();

  const { data: curProcessorData, isLoading: isCurPorcessorLoading } =
    API.getBasicQuery(`processors/${curProcessor?.id}`, '', !!curProcessor?.id);

  const { fields, isExcel, requiredRows, statements, allFieldKeys } =
    useCommonData(fileType, file);
  /**
   * Share data between the commission mapper and the commission preview
   */
  const [shareData, setShareData] = useState<ShareDataModel>({
    isExcel: false,
    sheets: [],
    fields: {},
    fileType: '',
    requiredRows: [],
    errors: {},
    allFieldKeys: [],
    fileData: {},
  });

  const sheets = useMemo(() => {
    if (spreadsheet) {
      return spreadsheet.getSheets();
    }
    return [];
  }, [spreadsheet]);

  useEffect(() => {
    setShareData((prev) => {
      const newData = {
        ...prev,
        isExcel,
        sheets,
        fields,
        allFieldKeys,
        fileType: fileType || '',
        requiredRows,
        errors,
        fileData,
      };
      return newData;
    });
  }, [
    fileType,
    fields,
    allFieldKeys,
    requiredRows,
    sheets,
    isExcel,
    errors,
    fileData,
  ]);

  // The key is the field name, the value is the index of the column in the spreadsheet
  const handleProcessFormChange = useCallback(
    (key: keyof ProcessFormModel, value: any) => {
      // If key is mapping, value is the mapping id, we need to update the mapping
      if (key === ProcessFormKey.mapping) {
        if (value !== 0) {
          const mapping = mpData.mappings?.find((m) => m.str_id === value);
          if (mapping) {
            setRowMapping(mapping.mapping);
          }
        } else {
          const _mappings = cloneDeep(processFormatData.mappingOptions);
          setRowMapping(_mappings);
        }
      } else if (key === ProcessFormKey.selectedSheet) {
        setSelectedSheet(value);
      }

      setProcessForm((prev) => {
        const newData = {
          ...prev,
          [key]: value,
        };
        return newData;
      });
    },
    [mpData.mappings, processFormatData.mappingOptions]
  );

  // Load the originnal data based on the method
  useEffect(() => {
    if (sheets.length && spreadsheet) {
      const _sheet = selectedSheet || sheets[0];
      setSelectedSheet(_sheet);
    }
  }, [sheets, spreadsheet, selectedSheet]);

  /**
   * Select process method
   */
  useEffect(() => {
    if (
      selectedSheet &&
      spreadsheet &&
      processForm.method === ProcessMethodE.Mapping
    ) {
      const curMetaData = spreadsheet.getJson(selectedSheet) as string[][];
      if (Object.prototype.toString.call(curMetaData) === '[object Array]') {
        const sheetData = statements.getDataInfo(curMetaData);
        setRangeData(sheetData.rangeData);
        setSelectedDataRange(sheetData.rangeData[0]);
      }
    } else if (
      processForm.method === ProcessMethodE.Processor ||
      !processForm.method
    ) {
      const selectFn = () => {
        if (rowData) {
          const { companies, method = '' } = rowData;
          const isAdobeExtract = method?.includes('adobeExtract');
          const isDocumentAI = method?.includes('documentAI');
          const isExtractTable = method?.includes('extractTable');

          const _targetList = mpData.processors.filter((p) => {
            let targetName = '';
            if (p.inner_name) {
              const innerNameList = p.inner_name.split('_');
              let innerCompanyNameArr = innerNameList.slice(
                0,
                innerNameList.length - 2
              );
              if (p.inner_name.endsWith('processor')) {
                innerCompanyNameArr = innerNameList.slice(
                  0,
                  innerNameList.length - 3
                );
              }
              targetName = innerCompanyNameArr.join(' ').toLowerCase();
            }

            let compareName = [
              companies?.company_name || '',
              ...(companies?.alias_list || []),
            ]
              .join(',')
              .toLowerCase();
            if (targetName.length < compareName.length) {
              [targetName, compareName] = [compareName, targetName];
            }

            return (
              compareName &&
              targetName &&
              compareName
                .split(',')
                .some((s) => s.includes(targetName) || targetName.includes(s))
            );
          });

          let processorList: any[] = [];
          if (isAdobeExtract) {
            processorList = _targetList.filter(
              (p) => p.method === 'adobeExtract'
            );
          } else if (isDocumentAI) {
            processorList = _targetList.filter(
              (p) => p.method === 'documentAI'
            );
          } else if (isExtractTable) {
            processorList = _targetList.filter(
              (p) => p.method === 'extractTable'
            );
          } else {
            processorList = _targetList.filter(
              (p) => p.method === 'spreadsheet'
            );
          }

          let target;
          if (companies?.company_name) {
            target = processorList.find((p) =>
              p.inner_name.includes(companies.company_name)
            );
          } else {
            target = processorList.length ? processorList[0] : null;
          }
          if (target && (target.extractions || target.document_str_id)) {
            handleProcessFormChange(
              ProcessFormKey.method,
              ProcessMethodE.Processor
            );
            handleProcessFormChange(ProcessFormKey.processor, target.str_id);
          } else {
            if (!processForm.method) {
              handleProcessFormChange(
                ProcessFormKey.method,
                ProcessMethodE.Mapping
              );
            }
            handleProcessFormChange(ProcessFormKey.processor, '');
          }
        }
      };
      selectFn();
    } else if (
      processForm.method === ProcessMethodE.Gemini &&
      shareData.fileData?.type === ProcessMethodE.Gemini
    ) {
      const { keys, values } = tool.convertMapToArray(shareData.fileData?.data);
      setProcessedData({
        data: values,
        fields: keys,
        version: ProcessResultE.Gemini,
      });
    }
  }, [
    selectedSheet,
    processForm.method,
    spreadsheet,
    mpData.processors,
    rowData,
    shareData.fileData,
  ]);

  /**
   * Load mapping data
   */
  useEffect(() => {
    if (
      selectedDataRange?.fields.length &&
      mpData.mappings.length &&
      processForm.method === ProcessMethodE.Mapping
    ) {
      if (processForm.mapping) {
        const mapping = mpData.mappings.find(
          (m) => m.str_id === processForm.mapping
        );
        if (mapping) {
          setRowMapping(mapping.mapping);
        }
      } else {
        const initialMapping = {};
        for (const [k, v] of Object.entries(fields)) {
          for (const [
            fileFieldIndex,
            fileField,
          ] of selectedDataRange.fields.entries()) {
            if (
              v.options?.includes(fileField?.toString()?.toLowerCase().trim())
            ) {
              initialMapping[k] = fileFieldIndex;
              break;
            }
          }
        }
        setRowMapping(initialMapping);
      }
    }
  }, [
    selectedDataRange,
    processForm.mapping,
    mpData.mappings,
    processForm.method,
  ]);

  useEffect(() => {
    if (processForm.method === ProcessMethodE.Mapping) {
      const missingFields = shareData.requiredRows.filter(
        (k) => !Object.keys(rowMapping).includes(k)
      );
      setMissingRequiredFields(missingFields);
    }
  }, [rowMapping, shareData.requiredRows, processForm.method]);

  /**
   * If using the processor, then process the data
   */
  useEffect(() => {
    const doAction = async () => {
      if (
        processForm.method === ProcessMethodE.Processor &&
        processForm.processor
      ) {
        const targetProcessor = mpData.processors?.filter(
          (p) => p.str_id === processForm.processor
        )?.[0];
        if (targetProcessor) {
          setCurProcessor(targetProcessor);
        }
      }
    };
    doAction();

    return () => {
      setProcessedData({
        data: [],
        fields: [],
        version: null,
      });
    };
  }, [processForm.processor, processForm.method, mpData.processors]);

  useEffect(() => {
    if (!isCurPorcessorLoading && curProcessorData) {
      try {
        let source = extraction;
        if (
          curProcessorData?.suggest_for &&
          sheets.includes(curProcessorData?.suggest_for)
        ) {
          setSelectedSheet(curProcessorData?.suggest_for);
        }
        if (isExcel && spreadsheet && selectedSheet) {
          source = spreadsheet.getJson(selectedSheet);
        }
        if (source && curProcessorData) {
          try {
            const processorFunc = eval(`${curProcessorData.processor}`);
            const libs = {
              document: rowData,
              tools: tool,
            };
            const res = processorFunc(source, libs);
            setProcessedData(res);
            const missingFields = shareData.requiredRows.filter(
              (k) => !res?.fields?.includes(k)
            );
            setMissingRequiredFields(missingFields);
          } catch (e) {
            setErrors({
              ...errors,
              dataRows: `Error while processing document: ${e}`,
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [curProcessorData, isCurPorcessorLoading, extraction, selectedSheet]);

  useEffect(() => {
    if (processedData.fields.length) {
      const curMetaData = [processedData.fields, ...processedData.data];
      const sheetData = statements.getDataInfo(curMetaData);
      setRangeData(sheetData.rangeData);
      setSelectedDataRange(sheetData.rangeData[0]);
      const tempMapping = convertFieldListToMapping(
        processedData?.fields ?? []
      ) as RowMappingModel;

      const fieldsInDB = Object.keys(shareData.fields);
      const fieldsInDBMappings = fieldsInDB.reduce((acc, cur) => {
        const opt = shareData.fields[cur].options;
        const tempMappingKeys = Object.keys(tempMapping);
        const fieldInDBMapping = tempMappingKeys.find((k) =>
          [cur, ...opt].includes(k.toLowerCase())
        );
        if (fieldInDBMapping) {
          acc[cur] = tempMapping[fieldInDBMapping];
        }
        return acc;
      }, {});
      setRowMapping(fieldsInDBMappings);
    }
  }, [processedData]);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <ProcessForm
          mpData={mpData}
          shareData={shareData}
          rowData={rowData}
          processForm={processForm}
          handleProcessFormChange={handleProcessFormChange}
          addActionCount={addActionCount}
        />
        <Divider sx={{ mt: 1, mb: 1 }} variant="middle" textAlign="center" />
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Box sx={{ height: '100%' }}>
            <ProcessMappingForm
              selectedDataRange={selectedDataRange}
              setSelectedDataRange={setSelectedDataRange}
              rangeData={rangeData}
              shareData={shareData}
              rowMapping={rowMapping}
              setRowMapping={setRowMapping}
              processForm={processForm}
              processedData={processedData}
              addActionCount={addActionCount}
            />
          </Box>

          <Box
            sx={{
              minWidth: 600,
              overflow: 'scroll',
              flex: 1,
            }}
          >
            <CommissionPreview
              rowMapping={rowMapping}
              setRowMapping={setRowMapping}
              selectedDataRange={selectedDataRange}
              processedData={processedData}
              setProcessedData={setProcessedData}
              shareData={shareData}
              processForm={processForm}
              setProcessFormatData={setProcessFormatData}
              setErrors={setErrors}
              missingRequiredFields={missingRequiredFields}
              handleProcessFormChange={handleProcessFormChange}
              addActionCount={addActionCount}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CommissionMapper;
