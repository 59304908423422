import { Chip, Skeleton, Tooltip } from '@mui/material';
import * as chrono from 'chrono-node';
import currency from 'currency.js';
import dayjs from 'dayjs';
import {
  ReportReviewStatuses,
  ReportReviewStatusesLabels,
} from 'common/globalTypes';
import { AccountIds } from 'common/constants';

import { checkType } from './helpers';
import { convertExcelDateToJSDate, isNill } from './tools';

const isTransGlobalAccount = (accountID) =>
  accountID === AccountIds.TRANSGLOBAL;

class Formatter {
  static currency = (s) => {
    if (s === undefined || s === '') return '';
    let s1 = s;
    if (typeof s1 === 'string') {
      s1 = s.trim();
    }
    if (typeof s1 === 'string') {
      s1 = s.replaceAll(',', '');
      s1 = s1.replaceAll('$', '');
    }
    if (typeof s1 === 'string' && s1.split('\n').length > 1) {
      [s1] = s.split('\n');
    }
    if (typeof s1 === 'string' && s1.split(' ').length > 1) {
      [s1] = s1.split(' ');
    }
    if (typeof s1 === 'string' && s1.endsWith('-')) {
      s1 = `-${s1.slice(0, -1)}`;
    }
    if (typeof s1 === 'string' && s1.startsWith('(') && s1.endsWith(')')) {
      s1 = `-${s1.slice(1, -1)}`;
    }
    s1 = parseFloat(s1);
    if (Number.isNaN(s1)) {
      s1 = '';
    } else {
      s1 = currency(s1).format();
      // +s1.toFixed(2) >= 0
      //   ? `$${s1.toLocaleString('en-US', {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     })}`
      //   : `-$${Math.abs(s1).toLocaleString('en-US', {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     })}`;
    }
    return s1;
  };

  // TODO: should make sure this is a number
  static commissionRate = (s) =>
    s !== undefined && s !== null && s !== '' && s !== '%'
      ? s?.toString()?.endsWith('%')
        ? s
        : `${s}%`
      : '';

  static pct = (val) => `${+(+val * 100).toFixed(2)}%`;

  // Takes in a numerical representation of a percentage (e.g. 0.8) and formats
  // it as a percentage (e.g. 80%)
  static percentage = (val) => {
    if (typeof val === 'string' || isNill(val)) {
      return val;
    }
    return val * 100 < 100
      ? `${(val * 100).toPrecision(3)}%`
      : `${Math.round(val * 100)}%`;
  };

  static date = (
    s,
    options = {
      format: 'MM/DD/YYYY',
    }
  ) => {
    let result = '';
    if (!s) {
      return '';
    }
    // check the value is a number or string number
    if (/^\d+$/.test(s)) {
      let mils = s;
      if (s.toString().length === 5) {
        mils = convertExcelDateToJSDate(+s);
      }
      const time = dayjs(mils).format(options.format);
      return time;
    }
    if (checkType(s, 'Date')) {
      result = dayjs(
        new Date(s.getTime() + s.getTimezoneOffset() * 60 * 1000)
      ).format(options.format);
    } else if (checkType(s, 'Number')) {
      console.error('Unsupported date format', s);
      let bom = dayjs('1/1/1900').add(s - 2, 'days');
      if (options.startOfMonth) {
        bom = bom.startOf('month');
      }
      result = bom.format(options.format);
    } else if (checkType(s, 'String')) {
      const parsedDate = chrono.parseDate(s);
      if (parsedDate) {
        // Hack for time zone offset
        let bom = dayjs(
          new Date(
            parsedDate.getTime() + parsedDate.getTimezoneOffset() * 60 * 1000
          )
        );
        if (options.startOfMonth) {
          bom = bom.startOf('month');
        }
        result = bom.format(options.format ?? 'MM/DD/YYYY');
      } else {
        console.error(`Couldn't parse '${s}' as a date.`);
        result = '';
      }
    }
    return result;
  };

  static dateTime = (date) => new Date(date).toLocaleString();

  static contact = (contact, opts) => {
    const isTransGlobal = opts?.account_id === AccountIds.TRANSGLOBAL;
    const _lastFirst = (isTransGlobal || opts?.last_first) ?? false;
    if (!contact) {
      return '';
    }
    const arr = [];
    const nameArr = [];
    if (contact.first_name) nameArr.push(contact.first_name.trim());
    if (contact.last_name) nameArr.push(contact.last_name.trim());
    if (_lastFirst) {
      nameArr.reverse();
      arr.push(nameArr.join(', '));
    } else {
      arr.push(nameArr.join(' '));
    }
    if (opts?.incl_email && contact.email)
      arr.push(`(${contact.email.trim()})`);
    const str = arr.join(' ');
    return str;
  };

  static contactInitials = (contact) => {
    if (!contact) {
      return '';
    }
    let initials = '';
    if (contact.first_name) initials += contact.first_name.trim()[0];
    if (contact.last_name) initials += contact.last_name.trim()[0];
    return initials.toUpperCase();
  };

  static boolean = (val) => {
    if (['TRUE', 'True', 'true', true].includes(val)) return 'Yes';
    if (['FALSE', 'False', 'false', false].includes(val)) return 'No';
    return val;
  };

  static fieldMatcher = (criteria) =>
    `"${criteria?.field}" ${criteria?.op} "${criteria?.value}"`;

  // Formatters returning html elements should generally only be used as tableFormatters or custom components
  static status = (val, opts) => {
    const statusMap = {
      completed: '✅',
      processing: '🔄',
      error: '⛔️',
      pending: '⏳',
      imported: '📥',
    };
    return (
      <Tooltip arrow title={val}>
        <span>{statusMap[val] || val}</span>
      </Tooltip>
    );
  };

  static actionByUser = (row, collection, action = 'created_by', opts) => {
    const actionProxyField = action.replace('_by', '_proxied_by');
    return collection ? (
      <>
        <span style={{ whiteSpace: 'nowrap' }}>
          {Formatter.contact(
            collection?.find((item) => item.uid === row[action])
          )}
        </span>
        {row[actionProxyField] && (
          <>
            <br />
            <span style={{ whiteSpace: 'nowrap' }}>
              {' '}
              (via{' '}
              {Formatter.contact(
                collection?.find((item) => item.uid === row.created_proxied_by)
              )}
              )
            </span>
          </>
        )}
      </>
    ) : (
      <Skeleton />
    );
  };

  static jsonToggleFormatted = (val) => {
    // TODO: Figure out why this results in a different number of hooks error
    // const [isFormatted, setIsFormatted] = useState(false);
    // return (
    //   <Box onClick={() => setIsFormatted(!isFormatted)}>
    //     {isFormatted ? (
    //       <pre style={{ lineHeight: 1 }}>
    //         <span style={{ fontSize: 12 }}>{JSON.stringify(val, null, 2)}</span>
    //       </pre>
    //     ) : (
    //       JSON.stringify(val)
    //     )}
    //   </Box>
    // );
    return JSON.stringify(val);
  };

  static policyNumber = (val, opts) => {
    if (isTransGlobalAccount(opts?.account_id)) {
      return val.toUpperCase();
    }
    return val;
  };

  static getDynamicSelectFormatter =
    (stringFn = (o) => o?.name, matchKey = 'id', defaultString = '') =>
    (val, collection) => {
      if (!Array.isArray(collection)) return <Skeleton />;
      const match =
        Array.isArray(collection) &&
        collection?.find((e) => e[matchKey] === val);
      const str = stringFn(match);
      return str || defaultString;
    };

  static numberRange = (start, end) => {
    if (![null, undefined].includes(start) && start === end) {
      return start;
    } else if (start && end) {
      return `${start}-${end}`;
    } else if (start) {
      return `${start}+`;
    } else if (end) {
      return `< ${end}`;
    } else {
      return 'any';
    }
  };

  static compGridCriterion = (val) =>
    val
      ? `${val.comp_grid_product.type} • ${val.comp_grid_product.name} • Years (${Formatter.numberRange(val.policy_year_start, val.policy_year_end)}) • Ages (${Formatter.numberRange(val.issue_age_start, val.issue_age_end)})${val.compensation_type ? ` • ${val.compensation_type}` : ''}${val.transaction_type ? ` • ${val.transaction_type}` : ''}`
      : '';

  static getLinkChipFormatter =
    (labelKey, paramKey, href, target = '_blank') =>
    (val, row) => {
      if (val && val[paramKey] && val[labelKey]) {
        return (
          <Chip
            component="a"
            href={`${href}${val[paramKey]}`}
            label={val[labelKey]}
            clickable
            target={target}
          />
        );
      }
      return null;
    };

  static statusChip = (status, opts) => {
    const colorMap = {
      green: '#edf7ed',
      greenDark: '#1e4620',
      blue: '#e5f6fd',
      blueDark: '#014361',
      yellow: '#fff4e5',
      yellowDark: '#663c00',
      red: '#fdeded',
      redDark: '#5f2120',
    };
    const _color =
      opts?.color ??
      colorMap[opts?.mapping?.[status]] ??
      opts?.mapping?.[status] ??
      '#ebebeb';
    const textColor = colorMap[`${opts?.mapping?.[status]}Dark`];
    return (
      <Chip
        sx={{
          color: textColor,
          backgroundColor: _color,
          borderRadius: 1,
          m: 0.25,
        }}
        size="small"
        label={status}
      />
    );
  };

  static documentType = (val) => {
    const mapping = {
      statement: 'Commission statement',
      report: 'Policy report',
    };
    return mapping[val] || val;
  };

  static reportStatusFormatter = (s) => {
    let color;
    if (
      s === ReportReviewStatuses.Approved ||
      s === ReportReviewStatusesLabels.Approved
    ) {
      color = '#a5d6a7';
    } else if (
      s === ReportReviewStatuses.InReview ||
      s === ReportReviewStatusesLabels.InReview
    ) {
      color = '#fff59d';
    } else if (
      s === ReportReviewStatuses.RequestUpdate ||
      s === ReportReviewStatusesLabels.RequestUpdate
    ) {
      color = '#ffcc80';
    } else if (
      s === ReportReviewStatuses.Rejected ||
      s === ReportReviewStatusesLabels.Rejected
    ) {
      color = '#f52525';
    } else if (
      s === ReportReviewStatuses.NonPayable ||
      s === ReportReviewStatusesLabels.NonPayable
    ) {
      color = '#ddd';
    } else if (
      s === ReportReviewStatuses.Draft ||
      s === ReportReviewStatusesLabels.Draft
    ) {
      color = '#ddd';
    } else if (
      s === ReportReviewStatuses.None ||
      s === ReportReviewStatusesLabels.None
    ) {
      color = 'transparent';
    } else {
      color = 'transparent';
    }
    return (
      <>
        <Chip sx={{ backgroundColor: color, my: 0.25, mr: 0.5 }} label={s} />
      </>
    );
  };
}

export default Formatter;
