import { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Download, History } from '@mui/icons-material';
import { cloneDeep } from 'lodash-es';
import { useOutletContext } from 'react-router-dom';

import Statements from '@/services/Statements';
import EnhancedTable from '@/components/molecules/EnhancedTable';
import Reconciliations from '@/services/Reconciliations';
import Reports from '@/services/Reports';
import { exportToCsv } from '@/services/helpers';
import { auth } from '@/firebase';
import { Roles, savedReportsGroupsTemplates } from '@/types';
import Formatter from '@/services/Formatter';
import CommissionCalcLog from './CommissionCalcLog';
import { useAccountStore, useRoleStore } from '@/store';
import API from '@/services/API';

interface ReportDataProps {
  selectedSnapshotReport: any;
  filteredData?: any[];
  isLoading: boolean;
  setOpenHistory: (open: boolean) => void;
  handleGroupingChange?: (event: any, newGrouping: string | null) => void;
  grouping?: string;
  handleViewChange?: (event: any, newGrouping: string | null) => void;
  view?: string;
  refetch?: () => void;
  showActions?: boolean;
  isHistoryView?: boolean;
}

const ReportData: React.FC<ReportDataProps> = ({
  selectedSnapshotReport,
  filteredData = [],
  isLoading,
  setOpenHistory,
  handleGroupingChange = null,
  grouping = '',
  handleViewChange = null,
  view = '',
  refetch = () => {},
  showActions = true,
  isHistoryView = false,
}): JSX.Element => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isDownloading, setIsDownloading] = useState(false);
  const { selectedAccount } = useAccountStore();
  const { userRole } = useRoleStore();
  const { openSnackbar } = useOutletContext<any>();

  const SavedReportsPatcher = API.getMutation('saved_reports/reports', 'PATCH');

  if (selectedSnapshotReport?.snapshot_data.reportPage === 'commissions') {
    const statements = new Statements('insurance', {
      account_id: selectedAccount?.accountId,
    });

    selectedSnapshotReport.snapshot_data.headers.forEach((header) => {
      // match statements.fields with headers based on label
      const field = Object.values(statements.fields).find(
        (field) => field.label === header.label
      );
      // Add a new field header.formatter if field is found using field.formatter
      if (field && field.formatter) {
        header.formatter = field.formatter;
      }
      if (field && field.tableFormatter) {
        header.tableFormatter = field.tableFormatter;
      }
      if (field && field.optionFormatter) {
        header.optionFormatter = field.optionFormatter;
      }
      if (field && field.optionValuer) {
        header.optionValuer = field.optionValuer;
      }
      if (field && field.render) {
        header.render = field.render;
      }
      // Custom formatters for commission payout reports
      if (
        selectedSnapshotReport.saved_report_group?.template ===
        savedReportsGroupsTemplates.COMMISSION_PAYOUT
      ) {
        if (header.id === 'agent_commissions') {
          header.tableFormatter = (val) => {
            if (!val) return '';
            return (
              <Box>
                {Object.entries(val)
                  .filter(([k, v]) => k !== 'total')
                  .map(([k, v]) => {
                    return (
                      <Chip
                        key={k}
                        label={`${Formatter.currency(v)}`}
                        sx={{ m: 0.1 }}
                      />
                    );
                  })}
              </Box>
            );
          };
        } else if (header.id === 'agent_commissions_log') {
          header.tableFormatter = (val) => {
            if (!val) return '';
            return (
              <Box>
                {Object.entries(val)
                  .filter(([k, v]) => k !== 'total')
                  .map(([k, v]: [string, any]) => {
                    return (
                      <Box key={k}>
                        {v.map((e, i) => (
                          <CommissionCalcLog commissionProfile={e} key={i} />
                        ))}
                      </Box>
                    );
                  })}
              </Box>
            );
          };
        } else if (header.id === 'agent_payout_rate') {
          header.tableFormatter = (val) => {
            if (!val) return '';
            return (
              <Box>
                {Object.entries(val)
                  .filter(([k, v]) => k !== 'total')
                  .map(([k, v]) => {
                    return (
                      <Chip
                        key={k}
                        label={`${Formatter.percentage(v)}`}
                        sx={{ m: 0.1 }}
                      />
                    );
                  })}
              </Box>
            );
          };
        }
      }
    });
    // Custom totals for commission payout reports
    if (
      selectedSnapshotReport?.snapshot_data.data?.totals &&
      selectedSnapshotReport.saved_report_group?.template ===
        savedReportsGroupsTemplates.COMMISSION_PAYOUT
    ) {
      const totals = selectedSnapshotReport.snapshot_data.data.totals;
      Object.entries(totals).forEach(([k, v]) => {
        // Remove the contact id for agent commissions totals
        if (k === 'agent_commissions' && typeof v === 'object' && v !== null) {
          totals[k] = Object.values(v)[0];
        }
      });
    }
  }

  if (selectedSnapshotReport?.snapshot_data.reportPage === 'reconciliation') {
    const reconciliations = new Reconciliations('insurance', '');

    selectedSnapshotReport.snapshot_data.headers.forEach((header) => {
      // match reconciliations.fields with headers based on label
      const field = Object.values(reconciliations.fields).find(
        (field) => field.label === header.label
      );
      // Add a new field header.formatter if field is found using field.formatter
      if (field && field.formatter) {
        header.formatter = field.formatter;
      }
      if (field && field.optionFormatter) {
        header.optionFormatter = field.optionFormatter;
      }
      if (field && field.optionValuer) {
        header.optionValuer = field.optionValuer;
      }
      if (field && field.getter) {
        header.getter = field.getter;
      }
      if (field && field.render) {
        header.render = field.render;
      }
    });
  }

  if (selectedSnapshotReport?.snapshot_data.reportPage === 'policies') {
    const reports = new Reports('insurance', {});

    selectedSnapshotReport.snapshot_data.headers.forEach((header) => {
      // match reports.fields with headers based on label
      const field = Object.values(reports.fields).find(
        (field) => field.label === header.label
      );
      // Add a new field header.formatter if field is found using field.formatter
      if (field && field.formatter) {
        header.formatter = field.formatter;
      }
      if (field && field.optionFormatter) {
        header.optionFormatter = field.optionFormatter;
      }
      if (field && field.optionValuer) {
        header.optionValuer = field.optionValuer;
      }
      if (field && field.render) {
        header.render = field.render;
      }
    });
  }

  const onExport = async () => {
    setIsDownloading(true);
    const idToken = await auth.currentUser?.getIdToken(true);
    await exportToCsv(
      {
        q: selectedSnapshotReport.str_id,
      },
      {
        // @ts-ignore
        idToken,
        endpoint: 'saved_reports',
        exportOptions: { fileName: selectedSnapshotReport.name },
      }
    );
    setIsDownloading(false);
  };

  const handleBulkEdit = async (selected, updateData) => {
    let data = cloneDeep(selectedSnapshotReport?.snapshot_data.data?.data);

    data = data
      .map((item) => {
        if (selected.includes(item.id)) {
          Object.keys(item).forEach((key) => {
            if (
              !Object.prototype.hasOwnProperty.call(updateData, key) &&
              key !== 'id'
            ) {
              delete item[key];
            }
          });
          Object.assign(item, updateData);
          return item;
        }
        return null;
      })
      .filter((item) => item !== null);

    const params = {
      id: selectedSnapshotReport.id,
      data,
    };

    const response = await SavedReportsPatcher.mutateAsync(params);

    if (response.status === 200) {
      openSnackbar(<Alert severity="success">Report updated!</Alert>);
      refetch();
    } else {
      openSnackbar(<Alert severity="error">Error updating report!</Alert>);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          mx: 2,
          flexWrap: isMobile ? 'wrap' : 'nowrap',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            mb: 0.5,
          }}
        >
          {typeof handleGroupingChange === 'function' &&
          typeof handleViewChange === 'function' ? (
            <>
              <ToggleButtonGroup
                sx={{ height: '34px', mr: 1 }}
                exclusive
                orientation={isMobile ? 'vertical' : 'horizontal'}
                color="primary"
                onChange={handleGroupingChange}
                size="small"
                value={grouping}
              >
                <ToggleButton value="policyNumber">
                  Group by policy
                </ToggleButton>
                <ToggleButton value="none">Commission data</ToggleButton>
              </ToggleButtonGroup>
              {userRole === Roles.ACCOUNT_ADMIN && (
                <ToggleButtonGroup
                  sx={{ height: '34px' }}
                  exclusive
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  color="primary"
                  onChange={handleViewChange}
                  value={view}
                >
                  <ToggleButton value="admin-view">Admin view</ToggleButton>
                  <ToggleButton value="prod-view">Producer view</ToggleButton>
                </ToggleButtonGroup>
              )}
            </>
          ) : null}
        </Box>
        {showActions && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              height: '20px',
            }}
          >
            <Button
              onClick={() => setOpenHistory(true)}
              startIcon={<History />}
              variant="outlined"
              size="small"
              sx={{ mr: 1 }}
            >
              Report history
            </Button>
            <Button
              disabled={isDownloading}
              onClick={onExport}
              startIcon={<Download />}
              variant="outlined"
              size="small"
            >
              Export
            </Button>
          </Box>
        )}
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mr: 2,
            mt: 2,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 500, mr: 0.5 }}>
            Loading...
          </Typography>
        </Box>
      ) : (
        <EnhancedTable
          dense
          // @ts-ignore
          headers={selectedSnapshotReport?.snapshot_data.headers}
          rows={
            filteredData
              ? filteredData
              : selectedSnapshotReport?.snapshot_data.data?.data
          }
          // @ts-ignore
          onBulkEdit={async (selected, updateData) => {
            await handleBulkEdit(selected, updateData);
          }}
          // @ts-ignore
          actionsEnabled={(v) => false}
          onDelete={!isHistoryView ? (v) => false : undefined}
          stickyHeader
          paginated
          showTotals={true}
          totals={selectedSnapshotReport?.snapshot_data.data?.totals}
        />
      )}
    </>
  );
};

export default ReportData;
