import { Box } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import useStopWatch from '@/contexts/useStopWatch';

const TimerCount = ({ count }, ref) => {
  const [timeFormat, setTimeFormat] = useState('');

  const { totalSeconds, start, pause } = useStopWatch();

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      pause();
    } else {
      start();
    }
  };

  useEffect(() => {
    let res = '';
    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    if (days > 0) {
      res += `${days}d `;
    }
    if (hours > 0) {
      res += `${hours}h `;
    }
    if (minutes > 0) {
      res += `${minutes}m `;
    }
    if (seconds > 0) {
      res += `${seconds}s `;
    }
    setTimeFormat(res);
  }, [totalSeconds]);

  useEffect(() => {
    handleVisibilityChange();
    window.addEventListener('visibilitychange', handleVisibilityChange, false);
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    totalSeconds,
  }));

  return (
    <>
      <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
        <Box sx={{ minWidth: '75px' }}>{`Time: ${timeFormat}`}</Box>
        <Box>{`Actions: ${count}`}</Box>
      </Box>
    </>
  );
};

export default forwardRef(TimerCount);
