import CloseIcon from '@mui/icons-material/Close';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { useQueryClient } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import FileUploads from '@/components/UploadModal/FileUploads';
import { useSetOriginFile, useSetOverrideFile } from '@/store/excelStore';
import PreviewUploads from './PreviewUploads';

const BaseModal = ({ open, handleClose, openSnackbar }) => {
  const [current, setCurrent] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [formModel, setFormModel] = useState({});
  const queryClient = useQueryClient();

  const [btnText, setBtnText] = useState('');
  const [titleText, setTitleText] = useState('');
  const uploadFileRef = useRef({
    current: {
      form: {},
    },
  });
  const previewFileRef = useRef({});

  const setOriginFile = useSetOriginFile();
  const setOverrideFile = useSetOverrideFile();

  const handleCancel = () => {
    setCurrent(0);
    uploadFileRef.current?.clearFile();
    setOriginFile(null);
    setOverrideFile(null);
    setFormModel({});
    handleClose(false);
  };

  const onCancel = () => {
    if (current === 0) {
      handleCancel();
      return;
    }
    setCurrent((prev) => prev - 1);
  };

  const showErrorMsg = (err = '') => {
    const alert = <Alert severity="error">{err}</Alert>;
    openSnackbar(alert);
  };

  const showSuccessMsg = (msg, sec = 3000) => {
    const alert = <Alert severity="success">{msg}</Alert>;
    openSnackbar(alert, sec);
  };

  const goNext = () => {
    if (current === 1) {
      handleCancel();
      return;
    }
    setCurrent((prev) => prev + 1);
  };

  const doStepOne = () => {
    setUploading(true);
    uploadFileRef.current?.submit();
    setUploading(false);
  };

  const doStepTwo = async () => {
    setUploading(true);
    const res = await previewFileRef.current?.submit();
    setUploading(false);

    if (res.error) {
      showErrorMsg(res.error);
      return;
    }
    // success
    const tip = "File uploaded. We'll let you know when the file is processed.";
    showSuccessMsg(tip, 8000);
    goNext();
    // Auto refresh page
    if (window.location.pathname === '/documents') {
      queryClient.invalidateQueries();
    }
  };

  const submit = () => {
    switch (current) {
      case 0:
        doStepOne();
        break;
      case 1:
        doStepTwo();
        break;
      // case 2:
      //   doStepThree();
      //   break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (current === 0) {
      setBtnText('Next');
      setTitleText('Upload file');
    } else if (current === 1) {
      setBtnText('Upload');
      setTitleText('Preview');
    }
  }, [current]);

  const steps = [
    {
      title: 'Select files',
      content: (
        <FileUploads
          ref={uploadFileRef}
          setCurrent={setCurrent}
          setFormModel={setFormModel}
          formModel={formModel}
          openSnackbar={openSnackbar}
        />
      ),
    },
    {
      title: 'Preview & Upload',
      content: <PreviewUploads ref={previewFileRef} formModel={formModel} />,
    },
  ];

  return (
    <Dialog
      open={open}
      fullScreen={current === 1}
      maxWidth={current === 1 ? '100%' : 'md'}
      sx={{
        background: 'transparent',
        padding: 2,
        minWidth: 800,
      }}
      onClose={handleCancel}
      PaperProps={{ sx: { borderRadius: 2 } }}
    >
      <DialogTitle>
        <Box className="flex items-center">
          <CloudSyncIcon />
          <Box className="pl-2">{titleText}</Box>
        </Box>
      </DialogTitle>
      <IconButton
        className="group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600"
        onClick={handleCancel}
      >
        <CloseIcon className="group-hover:rotate-180 transition-all origin-center" />
      </IconButton>
      <Divider />

      <DialogContent
        sx={{
          padding: 2,
          backgroundColor: '#fff',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stepper activeStep={current} alternativeLabel>
          {steps.map((step) => (
            <Step key={step.title}>
              <StepLabel sx={{ '.MuiStepLabel-label': { mt: 1 } }}>
                {step.title}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="p-2 pb-1 rounded flex-1">{steps[current].content}</div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>{current === 0 ? 'Cancel' : 'Back'}</Button>
        <LoadingButton onClick={submit} loading={uploading} variant="contained">
          {btnText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

BaseModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  openSnackbar: PropTypes.func,
};

export default BaseModal;
